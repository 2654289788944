import { styled } from '@mui/material/styles';

const SecondWrapper = styled('div')(({ theme }) => ({
  paddingTop: 100,
  [theme.breakpoints.down('md')]: {
    paddingTop: 100
  }
}));

export default SecondWrapper;
