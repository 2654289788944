import HeaderWrapper from 'ui-component/HeaderWrapper';
import SecondWrapper from 'ui-component/SecondWrapper';
import AppBar from 'ui-component/extended/AppBar';
import Footer from '../landing/Footer';
import LicensePricingBody from './LicensePricingBody';
import { useTheme } from '@mui/material';

const PricingPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderWrapper id="pricing">
        <AppBar />
      </HeaderWrapper>
      <div
        style={{
          minHeight: '100vh',
          background: `linear-gradient(360deg, ${theme.palette.background.paper} 1.09%, ${theme.palette.primary.light} 100%)`
        }}
      >
        <SecondWrapper>
          <LicensePricingBody />
        </SecondWrapper>
      </div>
      <Footer />
    </>
  );
};

export default PricingPage;
