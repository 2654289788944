import { Box, styled } from '@mui/material';

import { motion } from 'framer-motion';

import guidingDashboard from 'assets/images/landing/header/guiding/guiding-dashboard.png';

import course1 from 'assets/images/landing/header/guiding/guiding-course1.png';
import course2 from 'assets/images/landing/header/guiding/guiding-course2.png';
import course3 from 'assets/images/landing/header/guiding/guiding-course3.png';
import course4 from 'assets/images/landing/header/guiding/guiding-course4.png';
import course5 from 'assets/images/landing/header/guiding/guiding-course5.png';

// TODO: Scale image up for large displays
const HeaderImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  borderRadius: 20,
  transform: 'scale(1.5)',
  transformOrigin: '0 50%'
}));

const HeaderAnimationImage = styled('img')({
  maxWidth: '95%',
  filter: 'drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))'
});

const HeaderGuiding = () => (
  <Box sx={{ position: 'relative', mt: 8.75, zIndex: 9, width: '576px' }}>
    {/* Background */}
    <HeaderImage src={guidingDashboard} />
    {/* Course 1 (Law) */}
    <Box
      sx={{
        position: 'absolute',
        top: { md: -32 },
        left: { md: 188 },
        width: { md: 180 },
        animation: '8s slideY linear infinite'
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 150,
          damping: 30
        }}
      >
        <HeaderAnimationImage src={course1} />
      </motion.div>
    </Box>
    {/* Course 2 */}
    <Box
      sx={{
        position: 'absolute',
        top: { md: 80 },
        left: { md: 224 },
        width: { md: 180 },
        animation: '8s slideY linear infinite',
        animationDelay: '1s'
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 150,
          damping: 30,
          delay: 0.4
        }}
      >
        <HeaderAnimationImage src={course2} />
      </motion.div>
    </Box>
    {/* Course 3 */}
    <Box
      sx={{
        position: 'absolute',
        top: { md: 88 },
        left: { md: 400 },
        width: { md: 180 },
        animation: '8s slideY linear infinite',
        animationDelay: '2s'
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 150,
          damping: 30
        }}
      >
        <HeaderAnimationImage src={course3} />
      </motion.div>
    </Box>
    {/* Course 4 */}
    <Box
      sx={{
        position: 'absolute',
        top: { md: 180 },
        left: { md: 240 },
        width: { md: 180 },
        animation: '8s slideY linear infinite',
        animationDelay: '2s'
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 150,
          damping: 30,
          delay: 0.6
        }}
      >
        <HeaderAnimationImage src={course4} />
      </motion.div>
    </Box>
    {/* Course 5 */}
    <Box
      sx={{
        position: 'absolute',
        top: { md: 200 },
        left: { md: 432 },
        width: { md: 180 },
        animation: '8s slideY linear infinite',
        animationDelay: '3s'
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 150,
          damping: 30,
          delay: 0.4
        }}
      >
        <HeaderAnimationImage src={course5} />
      </motion.div>
    </Box>
  </Box>
);

export default HeaderGuiding;
