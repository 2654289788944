import { Button, Typography, useMediaQuery } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { useTheme } from '@mui/styles';

// assets
import imgIntro from 'assets/images/landing/solution/introCroped.png';
import imgBasics from 'assets/images/landing/solution/basics.png';
// import imgSgb8 from 'assets/images/landing/solution/sgb8.png';
import imgSgb9 from 'assets/images/landing/solution/sgb9.png';
import imgTheory from 'assets/images/landing/solution/theory.png';
import imgPractices from 'assets/images/landing/solution/practices.png';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type DiinuTimeLineItemProps = {
  title: string;
  intro?: JSX.Element;
  details?: JSX.Element;
  image: string;
  isLargeScreen: boolean;
};

export const DiinguTimelineItem = ({
  title,
  intro,
  details,
  image,
  isLargeScreen
}: DiinuTimeLineItemProps) => (
  <TimelineItem>
    <TimelineOppositeContent sx={{ textAlign: 'left', margin: 'auto 0' }}>
      <Typography variant="h4" width="100%" gutterBottom>
        {title}
      </Typography>
      {isLargeScreen ? <></> : <img src={image} width="100%" alt="imgIntro" />}
      {intro}
      {details ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="secondary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              flexDirection: 'row-reverse'
            }}
          >
            <Typography fontSize={12} sx={{ ml: 1.5 }}>
              <Button variant="outlined" size="small">
                Details
              </Button>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{details}</AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineDot variant="outlined" color="secondary" />
      <TimelineConnector />
    </TimelineSeparator>
    {isLargeScreen ? (
      <TimelineContent sx={{ margin: 'auto 0' }}>
        <img src={image} width="100%" alt="imgIntro" />
      </TimelineContent>
    ) : (
      <></>
    )}
  </TimelineItem>
);

const GuidingTimeline = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Timeline position={isLargeScreen ? 'alternate' : 'left'}>
      <Typography variant="h4" gutterBottom textAlign="center">
        Guiding
      </Typography>
      <DiinguTimelineItem
        title="Einführung"
        intro={
          <Typography variant="body2" py="0.5rem">
            Diingu stellt zu allen im Kontext der Schulbegleitung relevanten
            Behinderungsformen (seelische, geistige und körperliche
            Behinderungen) umfassende Lern- und Fortbildungsinhalte on-demand
            zur Verfügung, um eine spezifische und kompetente Unterstützung von
            Kindern und Jugendlichen mit den genannten Einschränkungen zu
            gewährleisten.
            <br />
            Das Guiding ist als Vor- oder Nachbereitung der Tätigkeit zu nutzen.
          </Typography>
        }
        image={imgIntro}
        isLargeScreen={isLargeScreen}
      />
      <DiinguTimelineItem
        title="I Allgemeines Guiding"
        intro={
          <Typography variant="body2" py="0.5rem">
            Das allgemeine Guiding beinhaltet störungsübergreifendes
            Grundlagenwissen über die Tätigkeit der Schulbegleitung - ganz
            unabhängig von der zugrundeliegenden Beeinträchtigung. Folgender
            Lernpfad wird allen Nutzer/innen verpflichtend <b>(„Must do“)</b>{' '}
            vorgegeben:
          </Typography>
        }
        details={
          <>
            <ol>
              <li>Einführungskurs: Grundlagen der Teilhabeassistenz</li>
              <li>Leitfaden: Kindeswohlgefährdung</li>
              <li>Rechtsgrundlagen</li>
            </ol>
            <Typography variant="body2" component="div" py="0.5rem">
              Folgende weitere allgemeine Guiding-Module werden zusätzlich als
              optionale Lerneinheiten <b>(„Can-do“)</b> zur Verfügung gestellt:
            </Typography>
            <ul>
              <li>Schutz- und Risikofaktoren im Kindes- und Jugendalter</li>
              <li>Methoden der Verhaltensänderung</li>
              <li>Deeskalationsstrategien im Schulalltag</li>
              <li>Suizidalität und selbstverletzendes Verhalten </li>
              <li>Umgang mit Mobbing </li>
              <li>Umgang mit Trauer und Verlusten</li>
              <li>Selbstfürsorge für Schulbegleitungen</li>
              <li>Grundlagen der Selbsterfahrung</li>
            </ul>
          </>
        }
        image={imgBasics}
        isLargeScreen={isLargeScreen}
      />
      <DiinguTimelineItem
        title="II Störungsspezifisches Guiding"
        intro={
          <Typography variant="body2" py="0.5rem">
            Der Lernpfad setzt sich nach Abschluss des allgemeinen Guidings mit
            dem störungsspezifischen Guiding fort. Dieser Lernpfad leitet sich
            aus dem individuellen Störungsbild des betroffenen Kindes
            automatisch ab. Dabei wird ein vertieftes theoretisches Verständnis
            der zugrundeliegenden Behinderung sowie umfangreiche
            Handlungskompetenzen im Umgang mit dieser vermittelt. Die Inhalte
            dienen der Sicherstellung einer konstanten Weiterbildung der
            zuständigen Schulbegleitung im Verlauf der Maßnahme.
          </Typography>
        }
        details={
          <>
            <Typography variant="body2" component="div" py="0.5rem">
              Das störungsspezifische Guiding beinhaltet umfangreiche Module zu
              folgenden
              <b> seelische Behinderungen (Rechtsgrundlage SGB VIII)</b>:
            </Typography>
            <ul>
              <li>Störungen des Sozialverhaltens</li>
              <li>AD(H)S</li>
              <li>Autismus-Spektrum-Störungen</li>
              <li>Depressionen im Kindes- und Jugendalter</li>
              <li>Soziale Ängste und Leistungsängste</li>
            </ul>
            <Typography variant="body2" component="div" py="0.5rem">
              Zudem umfasst das störungsspezifische Guiding umfangreiche Module
              zu folgenden
              <b>
                &nbsp;körperlichen und geistige Behinderungen (Rechtsgrundlage
                SGB IX)
              </b>
              :
            </Typography>
            <ul>
              <li>Intelligenzminderung (z.B. Trisomie 21/Down-Syndrom)</li>
              <li>
                Chronische körperliche Erkrankungen (z.B. Epilepsien,
                Herzerkrankungen, Spina bifida, Querschnittslähmung, Zerebrale
                Bewegungsstörungen)
              </li>
              <li>Fetales Alkoholsyndrom (FAS) </li>
              <li>
                Sprech- und Sprachstörungen (z.B. rezeptive oder expressive
                Sprachstörung)
              </li>
              <li>Lernbehinderungen (z.B. Lese-/Rechtschreibschwäche)</li>
              <li>Sinnesbeeinträchtigungen (z.B. Seh- und Hörschädigungen) </li>
            </ul>
            <Typography variant="body2" component="div" py="0.5rem">
              Die störungsspezifischen Inhalte gliedern sich jeweils in einen
              theoretischen und einen praktischen Teil:
            </Typography>
          </>
        }
        image={imgSgb9}
        isLargeScreen={isLargeScreen}
      />
      <DiinguTimelineItem
        title="1. Teil Theoretische Grundlagen"
        intro={
          <Typography variant="body2" py="0.5rem">
            Im ersten Teil werden die theoretischen Grundlagen zu den
            verschiedenen im Rahmen der Schulbegleitung relevanten Behinderungen
            vermittelt. Die NutzerInnen werden Woche für Woche durch die
            einzelnen Guiding-Module geführt. Hierzu zählen unter anderem:
          </Typography>
        }
        details={
          <>
            <ul>
              <li>Beschreibung der Kriterien der jeweiligen Behinderung</li>
              <li>Ursachen und aufrechterhaltende Faktoren</li>
              <li>Allgemeine Störungsmodelle</li>
              <li>Häufige Begleiterkrankungen und Folgeprobleme</li>
            </ul>
          </>
        }
        image={imgTheory}
        isLargeScreen={isLargeScreen}
      />
      <DiinguTimelineItem
        title="2. Teil Praxiswissen"
        intro={
          <Typography variant="body2" py="0.5rem">
            Im jeweiligen Praxisteil finden sich umfassende
            Handlungsempfehlungen zum Umgang mit den spezifischen
            Herausforderungen im Kontext der jeweiligen Behinderung. Der Fokus
            liegt hierbei explizit auf dem Erkennen und der Förderung der
            individuellen Stärken und Ressourcen der zu betreuenden Kinder und
            Jugendlichen:
          </Typography>
        }
        details={
          <>
            <ul>
              <li>Best-practices zum Umgang mit der jeweiligen Behinderung</li>
              <li>Störungsspezifische Förderaspekte</li>
              <li>Maßnahmen zur Lernförderung</li>
              <li>Förderung von Ressourcen</li>
              <li>Umgang mit Krisensituationen</li>
              <li>Spezifische Problemstellungen und Lösungsansätze</li>
              <li>
                Do&apos;s &amp; Don&apos;ts im Kontext der jeweiligen
                Behinderung
              </li>
            </ul>
            <Typography variant="body2" component="div" py="0.5rem">
              Die beschriebenen theoretischen und praktischen Inhalte werden
              durch konstante Lernverlaufskontrollen im Rahmen von
              Multiple-Choice Tests und Freitextaufgaben ergänzt.
            </Typography>
          </>
        }
        image={imgPractices}
        isLargeScreen={isLargeScreen}
      />
    </Timeline>
  );
};

export default GuidingTimeline;
