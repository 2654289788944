import { Container, Grid } from '@mui/material';

import { gridSpacing } from 'store/constant';

import imgBmwi from 'assets/images/landing/partners/bmwi-removebg-preview.png';
import imgExist from 'assets/images/landing/partners/exist-removebg-preview.png';
import imgEsf from 'assets/images/landing/partners/esf-removebg-preview.png';
import imgEu from 'assets/images/landing/partners/eu-removebg-preview.png';
import imgZzg from 'assets/images/landing/partners/zzg-removebg-preview.png';
import imgFgp from 'assets/images/landing/partners/fgp_1-removebg-preview.png';

const PressPartners = () => (
  <Container sx={{ my: 4 }}>
    <Grid
      container
      spacing={gridSpacing}
      alignItems="center"
      alignContent="center"
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        spacing={gridSpacing}
        alignContent="center"
      >
        <Grid item key="BMWi" xs={5} sm={4} md={3} lg={2}>
          <a
            href="https://www.bmwi.de/Navigation/DE/Home/home.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgBmwi} alt="BMWi" loading="lazy" width="110%" />
          </a>
        </Grid>
        <Grid item key="imgExist" xs={6} sm={4} md={3} lg={2}>
          <a
            href="https://www.exist.de/EXIST/Navigation/DE/Home/home.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgExist} alt="imgExist" loading="lazy" width="100%" />
          </a>
        </Grid>
        <Grid item key="imgEsf" xs={6} sm={4} md={3} lg={2}>
          <a
            href="https://www.esf.de/portal/DE/Startseite/inhalt.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgEsf} alt="imgEsf" loading="lazy" width="100%" />
          </a>
        </Grid>
        <Grid item key="imgEu" xs={6} sm={4} md={3} lg={2}>
          <a
            href="https://ec.europa.eu/esf/home.jsp?langId=de"
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgEu} alt="imgEu" loading="lazy" width="100%" />
          </a>
        </Grid>
        <Grid item key="imgZzg" xs={6} sm={4} md={3} lg={2}>
          <a
            href="https://www.esf.de/portal/DE/ESF-2014-2020/inhalt.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgZzg} alt="imgZzg" loading="lazy" width="100%" />
          </a>
        </Grid>
        <Grid item key="imgUr" xs={6} sm={4} md={2} lg={2}>
          <a
            href="https://www.uni-regensburg.de/humanwissenschaften/geistigbehindertenpaedagogik/forschung/index.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgFgp} alt="imgUr" loading="lazy" width="100%" />
          </a>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default PressPartners;
