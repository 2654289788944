import HeaderWrapper from 'ui-component/HeaderWrapper';
import SecondWrapper from 'ui-component/SecondWrapper';
import AppBar from 'ui-component/extended/AppBar';
import Footer from '../landing/Footer';

import SolutionBody from './solutionBody';

const PricingPage = () => (
  <>
    <HeaderWrapper id="pricing">
      <AppBar />
    </HeaderWrapper>
    <div style={{ minHeight: '100vh' }}>
      <SecondWrapper>
        <SolutionBody />
      </SecondWrapper>
    </div>
    <Footer />
  </>
);

export default PricingPage;
