// material-ui
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';

import FadeInWhenVisible from 'utils/Animation';

import { motion } from 'framer-motion';

import graph1 from 'assets/images/landing/header/management/activeCard.png';
import graph2 from 'assets/images/landing/header/management/avgChapterCard.png';
import graph3 from 'assets/images/landing/header/management/totalChapterCard.png';

import pdf1 from 'assets/images/landing/management/PDF1.png';
import pdf2 from 'assets/images/landing/management/PDF2.png';
import pdf3 from 'assets/images/landing/management/PDF3.png';
import pdf4 from 'assets/images/landing/management/PDF4.png';

// ==============================|| LANDING - CUSTOMIZE ||============================== //

const ManagementSection = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 1.5, sm: 2.5, md: 3, lg: 5 }}
      >
        <Grid item xs={12}>
          <FadeInWhenVisible>
            <Typography
              variant="h1"
              color="secondary"
              textAlign="center"
              sx={{ pb: '1rem', mb: { xs: 6, md: 4 } }}
            >
              Einfache Verwaltung <br /> um Ihr Leitungspersonal zu entlasten.
            </Typography>
          </FadeInWhenVisible>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
                Visualisierung der Lernaktivität
              </Typography>
              <Typography
                sx={{
                  zIndex: '99',
                  width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' },
                  maxWidth: '44ch',
                  pb: 2
                }}
              >
                Diingu analysiert die Lernaktivität und gibt Ihrem
                Leitungspersonal Handlungsempfehlungen. Damit Ihre
                Schulbegleitungen besser qualifiziert sind.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: { xs: 20, md: 24 } }}>
            {/* AVG chapters */}
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 16, md: 64, lg: 0 },
                right: { xs: 120, sm: 220, md: 0 },
                width: { xs: 220, lg: 360 },
                zIndex: 3
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={graph3}
                    alt="Card with a line chart showing the average chapters per assistant"
                  />
                </Stack>
              </motion.div>
            </Box>
            {/* Active assistants */}
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 26, md: 74, lg: 40 },
                right: { xs: 0, sm: 100, md: 160 },
                width: { xs: 220, lg: 360 },
                zIndex: 2
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={graph1}
                    alt="Card with a pie chart showing the amount of active and inactive assistants"
                  />
                </Stack>
              </motion.div>
            </Box>
            {/* Courses completed */}
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 96, md: 144, lg: 160 },
                right: { xs: 32, sm: 132, lg: 24 },
                width: { xs: 220, lg: 360 },
                zIndex: 1
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={graph2}
                    alt="Card with a bar chart showing the amount of completed courses per day"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {!isLargeScreen && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
                  Reporting leicht gemacht
                </Typography>
                <Box display="flex" alignItems="end" justifyContent="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      pb: 2,
                      maxWidth: '45ch'
                    }}
                  >
                    Automatisierte Dokumentation über alle abgeschlossenen
                    Fortbildungen. Zur Erfüllung Ihrer Nachweispflicht und für
                    mehr Transparenz gegenüber Ämtern.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: { xs: 16, lg: 24 } }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 0, lg: 0 },
                left: { xs: 0, sm: 120, md: 0 },
                width: { xs: 340, lg: 480 },
                zIndex: 1
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={pdf1}
                    alt="Card with a reporting pdf showing completed courses of an assistant"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 40, lg: 40 },
                left: { xs: 0, sm: 120, md: 0 },
                width: { xs: 340, lg: 480 },
                zIndex: 2
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={pdf2}
                    alt="Card with a reporting pdf showing completed courses of an assistant"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 80, lg: 80 },
                left: { xs: 0, sm: 120, md: 0 },
                width: { xs: 340, lg: 480 },
                zIndex: 3
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={pdf3}
                    alt="Card with a reporting pdf showing completed courses of an assistant"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 120, lg: 120 },
                left: { xs: 0, sm: 120, md: 0 },
                width: { xs: 340, lg: 480 },
                zIndex: 4
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -160px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={pdf4}
                    alt="Card with a reporting pdf showing completed courses of an assistant"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {isLargeScreen && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="secondary" sx={{ mb: 2 }}>
                  Reporting leicht gemacht
                </Typography>
                <Typography
                  sx={{
                    zIndex: '99'
                  }}
                >
                  Automatisierte Dokumentation über alle abgeschlossenen
                  Fortbildungen. Zur Erfüllung Ihrer Nachweispflicht und für
                  mehr Transparenz gegenüber Ämtern.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default ManagementSection;
