// material-ui
import HeaderWrapper from 'ui-component/HeaderWrapper';
import SecondWrapper from 'ui-component/SecondWrapper';

// project imports
import LegalContent from './legal';
import Footer from '../landing/Footer';
import AppBar from 'ui-component/extended/AppBar';

// =============================|| LANDING MAIN ||============================= //

const Legal = () => (
  <>
    <HeaderWrapper id="legal">
      <AppBar />
    </HeaderWrapper>
    <div style={{ minHeight: '100vh' }}>
      <SecondWrapper>
        <LegalContent />
      </SecondWrapper>
    </div>
    <Footer />
  </>
);

export default Legal;
