import { Container, Grid, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';

import imgBmwi from 'assets/images/landing/partners/bmwi.png';
import imgExist from 'assets/images/landing/partners/exist.png';
import imgEsf from 'assets/images/landing/partners/esf.png';
import imgEu from 'assets/images/landing/partners/eu.png';
import imgZzg from 'assets/images/landing/partners/zzg.png';
import imgUr from 'assets/images/landing/partners/ur_croped.png';
import imgAsAg from 'assets/images/landing/partners/asag.png';
import imgInvest from 'assets/images/landing/partners/invest.png';
import { KeyedObject } from 'types';
import { useEffect, useRef } from 'react';

interface PartnersProps extends KeyedObject {
  setShowFab: (arg0: boolean) => void;
}

const Partners = ({ setShowFab }: PartnersProps) => {
  const headerRef = useRef(null);

  useEffect(() => {
    const current = headerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowFab(false);
        }
      },
      {
        threshold: 0.1
      }
    );

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [setShowFab]);

  return (
    <Container>
      <Grid container spacing={gridSpacing} justifyContent="center">
        <Grid item xs={12} md={10} xl={8} textAlign="center">
          <Typography
            variant="h3"
            sx={{ pt: 4, pb: 8, fontWeight: 500 }}
            ref={headerRef}
          >
            Ausgezeichnet und gefördert von:
          </Typography>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            alignContent="center"
          >
            {/* EXIST */}
            <Grid item key="BMWi" xs={6} sm={4} md={3} lg={2}>
              <a
                href="https://www.bmwi.de/Navigation/DE/Home/home.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={imgBmwi}
                  alt="Logo BMWi"
                  loading="lazy"
                  width="100%"
                />
              </a>
            </Grid>
            <Grid item key="imgExist" xs={6} sm={4} md={3} lg={2}>
              <a
                href="https://www.exist.de/EXIST/Navigation/DE/Home/home.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={imgExist}
                  alt="Logo EXIST"
                  loading="lazy"
                  width="100%"
                />
              </a>
            </Grid>
            <Grid item key="imgEsf" xs={6} sm={4} md={3} lg={2}>
              <a
                href="https://www.esf.de/portal/DE/Startseite/inhalt.html"
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgEsf} alt="Logo ESF" loading="lazy" width="100%" />
              </a>
            </Grid>
            <Grid item key="imgEu" xs={6} sm={4} md={4} lg={2}>
              <a
                href="https://ec.europa.eu/esf/home.jsp?langId=de"
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgEu} alt="Logo EU" loading="lazy" width="100%" />
              </a>
            </Grid>
            <Grid item key="imgZzg" xs={6} sm={4} md={4} lg={2}>
              <a
                href="https://www.esf.de/portal/DE/ESF-2014-2020/inhalt.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={imgZzg}
                  alt="Logo Zusammen. Zukunft. Gestalten."
                  loading="lazy"
                  width="100%"
                />
              </a>
            </Grid>
            {/* Partners */}
            <Grid
              item
              key="imgInvest"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              sx={{ px: 4 }}
            >
              <a
                href="https://www.bafa.de/DE/Wirtschaft/Beratung_Finanzierung/Invest/invest_node.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={imgInvest}
                  alt="Logo Invest"
                  loading="lazy"
                  width="100%"
                  style={{ transform: 'scale(0.9)' }}
                />
              </a>
            </Grid>
            <Grid item key="imgAsAg" xs={12} sm={6} lg={4}>
              <a href="https://as-bremen.de/" target="_blank" rel="noreferrer">
                <img
                  src={imgAsAg}
                  alt="Logo As Ag Bremen"
                  loading="lazy"
                  width="100%"
                  style={{ transform: 'scale(0.8)' }}
                />
              </a>
            </Grid>
            <Grid item key="imgUr" xs={12} sm={6} lg={4}>
              <a
                href="https://www.uni-regensburg.de/humanwissenschaften/geistigbehindertenpaedagogik/forschung/index.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={imgUr}
                  alt="Logo Universität Regensburg"
                  loading="lazy"
                  width="100%"
                  style={{ transform: 'scale(0.8)' }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Partners;
