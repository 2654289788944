import { Button, Link, Stack, useTheme } from '@mui/material';
import Logo from 'ui-component/Logo';

import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

const DesktopAppBar = () => {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Link href="#/" display="flex" alignItems="center">
        <Logo />
      </Link>
      {/* Desktop App Bar */}
      <Stack direction="row" spacing={2}>
        <Button
          sx={{ color: theme.palette.primary.main }}
          component={Link}
          href="#/"
        >
          Home
        </Button>
        {/* <Button
          sx={{ color: theme.palette.primary.main }}
          component={Link}
          href="#/solution"
        >
          Leistung
        </Button> */}
        <Button
          sx={{ color: theme.palette.primary.main }}
          component={Link}
          href="#/pricing"
        >
          Preise
        </Button>
        <Button
          sx={{ color: theme.palette.primary.main }}
          component={Link}
          href="#/team"
        >
          Team
        </Button>
        <Button
          sx={{ color: theme.palette.primary.main }}
          component={Link}
          href="#/press"
        >
          Presseportal
        </Button>
      </Stack>
      <Stack
        direction="row"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' }
        }}
        spacing={2}
      >
        <Button
          component={Link}
          variant="outlined"
          // color="secondary"
          href="https://app.diingu.de"
          sx={{
            textTransform: 'none'
          }}
        >
          Anmelden
        </Button>
        <Button
          component={Link}
          variant="contained"
          href="https://auth.app.diingu.de/realms/diingu/protocol/openid-connect/registrations?client_id=diingu-alb-client&response_type=code&scope=openid%20email&redirect_uri=https://app.diingu.de/&kc_locale=de"
          sx={{ textTransform: 'none' }}
          endIcon={<LoginRoundedIcon />}
        >
          Registrieren
        </Button>
      </Stack>
    </Stack>
  );
};

export default DesktopAppBar;
