import { Grid, Typography, Button, Stack, Box } from '@mui/material';

import { gridSpacing } from 'store/constant';

import TeamCard from 'ui-component/cards/TeamCard';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import image from 'assets/images/people/daria.jpg';

const PressHeader = () => {
  return (
    <Grid
      container
      spacing={gridSpacing}
      justifyContent="space-between"
      sx={{ mt: { xs: 0, lg: '8vh' } }}
    >
      <Grid item container xs={12} md={7} lg={5} spacing={gridSpacing}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            color="primary"
            sx={{
              maxWidth: '40ch'
            }}
          >
            Liebe Medienschaffende, herzlich willkommen auf unserem
            Presseportal!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              maxWidth: '50ch'
            }}
          >
            Wir freuen uns über Ihr Interesse an Diingu und unterstützen Sie
            gerne bei Ihrer journalistischen Arbeit! Für Presseanfragen und
            weitere Informationen steht Ihnen unser Pressekontakt jederzeit
            unter presse@diingu.de zur Verfügung.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              maxWidth: '50ch'
            }}
          >
            Folgende Dateien könnten Sie bei Ihrer Arbeit unterstützen:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              href="/Diingu-Einblick.pdf"
              target="_blank"
              endIcon={<DownloadRoundedIcon />}
            >
              Diingu Einblick
            </Button>
            <Button
              href="/diingu-pressefotos.zip"
              variant="outlined"
              endIcon={<DownloadRoundedIcon />}
            >
              Fotos
            </Button>
            <Button
              href="/diingu-logos.zip"
              variant="outlined"
              endIcon={<DownloadRoundedIcon />}
            >
              Logos
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        sx={{
          display: { xs: 'none', md: 'inline' },
          justifyContent: 'center',
          mt: { xs: 4, md: 0 }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '260px'
          }}
        >
          <TeamCard
            imageUrl={image}
            name={
              <>
                Daria <br /> Lukiyanova
              </>
            }
            overlayText={<></>}
            title="Pressesprecherin"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PressHeader;
