import { FunctionComponent, useEffect, useRef } from 'react';

// material-ui
import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { gridSpacing } from 'store/constant';
import { KeyedObject } from 'types';
import SubCard from 'ui-component/cards/SubCard';

// assets
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';

import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';

interface ItemProps extends KeyedObject {
  icon: JSX.Element;
  text: string;
}

// =============================|| LANDING - FEATURE PAGE ||============================= //

interface BenefitsProps extends KeyedObject {
  setShowFab: (arg0: boolean) => void;
}

const Benefits = ({ setShowFab }: BenefitsProps) => {
  const theme = useTheme();
  const headerRef = useRef(null);

  useEffect(() => {
    const current = headerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowFab(true);
        }
      },
      {
        threshold: 0.1
      }
    );

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [setShowFab]);

  const Item = ({ icon, text }: ItemProps) => (
    <ListItem
      sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}
    >
      <ListItemIcon
        sx={{ color: theme.palette.secondary.main, fontSize: '3rem' }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        sx={{ pl: 2 }}
        primary={text}
        primaryTypographyProps={{
          style: { color: theme.palette.secondary.main },
          variant: 'subtitle2'
        }}
      />
    </ListItem>
  );

  type BenefitsCardProps = {
    title: string;
  };

  const BenefitsCard: FunctionComponent<BenefitsCardProps> = ({
    title,
    children
  }) => (
    <SubCard
      contentSX={{
        px: { xs: 2, lg: 4 },
        py: 4,
        height: '90%'
      }}
      sx={{
        boxShadow: theme.shadows[8],
        height: '100%'
      }}
      disableHover
    >
      <Typography
        variant="h3"
        color="secondary"
        textAlign="center"
        sx={{ pb: 2 }}
      >
        {title}
      </Typography>
      <Box sx={{ height: '100%' }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          {children}
        </List>
      </Box>
    </SubCard>
  );

  return (
    <Container>
      <Grid container spacing={gridSpacing} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h1"
            component="div"
            color="secondary"
            sx={{ mb: 2, mt: 8 }}
            ref={headerRef}
          >
            Die Vorteile von Diingu für Ihren Träger auf einen Blick:
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BenefitsCard title="Schulbegleitung">
            <Item
              icon={<MenuBookRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Behinderungsspezifische Fortbildung"
            />
            <Item
              icon={<WorkspacePremiumRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Zertifikat für jeden absolvierten Kurs"
            />
            <Item
              icon={<ImportantDevicesRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Zeit- und ortsunabhängig"
            />
          </BenefitsCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BenefitsCard title="Leitungspersonal">
            <Item
              icon={<QueryStatsRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Unterstützung der Verwaltung durch Reporting"
            />
            <Item
              icon={<TrendingUpRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Aufgabenfeld wird effizienter gestaltet"
            />
            <Item
              icon={<ScheduleRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Mehr Zeit für Fallbesprechungen"
            />
          </BenefitsCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BenefitsCard title="Geschäftsführung">
            <Item
              icon={<EuroRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Preisvorteil durch kostengünstigere Fortbildungen"
            />
            <Item
              icon={<BlurOnRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Teil der digitalen Vorreiter in der sozialen Branche"
            />
            <Item
              icon={<ThumbUpRoundedIcon sx={{ fontSize: '2rem' }} />}
              text="Besser qualifiziertes Personal"
            />
          </BenefitsCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Benefits;
