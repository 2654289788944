import { styled } from '@mui/material/styles';

const HeaderWrapper = styled('div')(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'clip',
  background: `linear-gradient(360deg, ${theme.palette.grey[100]} 1.09%, ${theme.palette.primary.light} 100%)`,
  [theme.breakpoints.down('md')]: {}
}));

export default HeaderWrapper;
