import {
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { gridSpacing } from 'store/constant';

const TeamHeader = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <Grid
        container
        spacing={gridSpacing}
        textAlign="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h1" color="primary">
            Unsere Mission ist, dass Inklusion gelingt!
          </Typography>
        </Grid>
        {isXs && (
          <Grid item md={10} xs={12}>
            <Typography sx={{ fontSize: '18px' }}>
              Unsere digitale Plattform Diingu stellt Trägern der
              so&shy;zia&shy;len Bran&shy;che pä&shy;dago&shy;gisches Know-how
              bereit und ver&shy;ein&shy;facht oben&shy;drein in&shy;terne
              Ar&shy;beits&shy;pro&shy;zes&shy;se. Die
              hoch&shy;qua&shy;li&shy;ta&shy;ti&shy;ven In&shy;hal&shy;te
              wer&shy;den dabei von ei&shy;nem Experten&shy;team aus den
              Be&shy;rei&shy;chen in&shy;klu&shy;si&shy;ve
              Pä&shy;da&shy;go&shy;gik, Psy&shy;cho&shy;lo&shy;gie und
              Psychiatrie entwickelt.
            </Typography>
          </Grid>
        )}
        {!isXs && (
          <>
            <Grid item md={10} xs={12}>
              <Typography>
                Mit Diingu, unserer digitalen Plattform, stellen wir
                pädagogisches Know-how für die soziale Branche bereit. Damit
                wollen wir dazu beitragen, dass Inklusion auf allen Ebenen
                gelingt.
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography>
                Unsere Lösung hat neben dem Vereinfachen von Arbeitsprozessen
                sozialer Träger vor allem ein Ziel: Mit hochqualitativen
                Inhalten dazu beitragen, dass Menschen mit spezifischen
                Bedürfnissen besser geholfen werden kann. Deshalb werden unsere
                Inhalte von einem Team aus WissenschaftlerInnen und ExpertInnen
                aus den Bereichen inklusive Pädagogik, Psychologie und
                Psychiatrie entwickelt.
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default TeamHeader;
