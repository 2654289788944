// import { useState } from 'react';

import {
  Button,
  // CardContent,
  // CardMedia,
  Container,
  Grid,
  Typography
  // useMediaQuery
} from '@mui/material';
// import { Theme } from '@mui/material/styles';
// import Card from '@mui/material/Card';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import Carousel from 'react-material-ui-carousel';

// import SubCard from 'ui-component/cards/SubCard';
// import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';
import GuidingTimeline from './GuidingTimeline';

// import imgGuiding from 'assets/images/landing/3pillars/guiding.png';
// import imgMonitoring from 'assets/images/landing/3pillars/monitoring.png';
// import imgMethods from 'assets/images/landing/3pillars/methods.png';

// interface ItemProps {
//   name: string;
//   description: JSX.Element;
//   theme: Theme;
// }

// const Item = ({ name, description, theme }: ItemProps) => (
//   <Grid
//     item
//     container
//     alignItems="center"
//     justifyContent="center"
//     spacing={gridSpacing}
//   >
//     <Grid item>
//       <Card sx={{ maxWidth: 800, boxShadow: theme.shadows[16], my: 6, mr: 2 }}>
//         <CardContent>
//           <Typography variant="h4" component="div" gutterBottom>
//             {name}
//           </Typography>
//           {description}
//         </CardContent>
//       </Card>
//     </Grid>
//   </Grid>
// );

const SolutionCarousel = () => {
  // const [carouselIndex, setCarouselIndex] = useState(0);

  // const theme = useTheme();
  // const items = [
  //   {
  //     name: 'Monitoring & Dokumentation',
  //     description: (
  //       <Typography variant="body2">
  //         Mittels Diingu&apos;s Monitoring-Tool wird eine standardisierte
  //         Erfassung und Auswertung von Beobachtungsdaten über das Kind in sechs
  //         unterschiedlichen Verhaltensdimensionen ermöglicht. Die erfassten
  //         Beobachtungsdaten werden sowohl grafisch als auch in Textform
  //         dargestellt. Hierdurch wird die Identifikation der individuellen
  //         Problembereiche bzw. Förderaspekte des Kindes unterstützt.
  //         Gleichzeitig wird der Entwicklungsverlauf des Kindes engmaschig über
  //         das Schuljahr hinweg sichtbar gemacht und dokumentiert. Diese
  //         Auswertung kann dann als Grundlage für den professionellen Austausch
  //         aller Akteure im Kontext der Schulbegleitung (z.B. im Rahmen von
  //         Teilhabe- und Hilfeplangesprächen) verwendet werden.
  //       </Typography>
  //     )
  //   },
  //   {
  //     name: 'Methodenkoffer',
  //     description: (
  //       <Typography variant="body2">
  //         Auf Basis der erfassten Beobachtungs- und Verlaufsdaten im Rahmen des
  //         Monitorings stellt Diingu allen Beteiligten automatisiert kurze und
  //         leicht umsetzbare Übungen zur unterstützenden Anwendung im
  //         Schulkontext zur Verfügung (= „Methodenkoffer“).
  //       </Typography>
  //     )
  //   }
  // ];

  // const GuidingItem: JSX.Element[] = [<GuidingTimeline />];
  // const textItems = items.map((item, i) => (
  //   <Item
  //     key={i}
  //     name={item.name}
  //     description={item.description}
  //     theme={theme}
  //   />
  // ));
  // console.log(textItems);

  // const carouselItems = [<GuidingTimeline key="carousel" />, ...textItems];
  // const carouselItems = [<GuidingTimeline key="carousel" />];
  // console.log(carouselItems);

  // const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container>
      <Grid container spacing={gridSpacing} alignContent="center">
        {/* <Grid item xs={12} md={4}>
          <Card
            sx={{
              maxWidth: 345,
              boxShadow: theme.shadows[16],
              height: '100%',
              mx: 'auto',
              ':hover': {
                boxShadow: theme.shadows[8]
              },
              border: '2px solid',
              borderColor:
                carouselIndex === 0 && isLargeScreen
                  ? theme.palette.primary.main
                  : theme.palette.background.paper
            }}
            onClick={() => setCarouselIndex(0)}
          >
            <CardMedia component="img" image={imgGuiding} alt="Guiding" />
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Guiding
              </Typography>
              <Typography variant="h5">
                Fortbildungen leicht gemacht!
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
        >
          <Accordion
            sx={{
              marginBottom: 4,
              marginTop: -1,
              backgroundColor: 'transparent'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                flexDirection: 'column'
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none' }}
              >
                <Typography fontSize={13}>Mehr erfahren</Typography>
              </Button>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <GuidingTimeline />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Card
            sx={{
              maxWidth: 345,
              boxShadow: theme.shadows[16],
              height: '100%',
              mx: 'auto',
              ':hover': {
                boxShadow: theme.shadows[8]
              },
              border: '2px solid',
              borderColor:
                carouselIndex === 1 && isLargeScreen
                  ? theme.palette.primary.main
                  : theme.palette.background.paper
            }}
            onClick={() => setCarouselIndex(1)}
          >
            <CardMedia component="img" image={imgMonitoring} alt="Guiding" />
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Monitoring &amp; Dokumentation
              </Typography>
              <Typography variant="h5">
                In Entwicklung und bald verfügbar
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
        >
          <Accordion
            sx={{
              marginBottom: 4,
              marginTop: -1,
              backgroundColor: 'transparent'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                flexDirection: 'column'
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none' }}
              >
                <Typography fontSize={13}>Mehr erfahren</Typography>
              </Button>
            </AccordionSummary>
            <AccordionDetails>{items[0].description}</AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              maxWidth: 345,
              boxShadow: theme.shadows[16],
              height: '100%',
              mx: 'auto',
              ':hover': {
                boxShadow: theme.shadows[8]
              },
              border: '2px solid',
              borderColor:
                carouselIndex === 2 && isLargeScreen
                  ? theme.palette.primary.main
                  : theme.palette.background.paper
            }}
            onClick={() => setCarouselIndex(2)}
          >
            <CardMedia component="img" image={imgMethods} alt="Guiding" />
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Methodenkoffer
              </Typography>
              <Typography variant="h5">In Planung</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
        >
          <Accordion
            sx={{
              marginBottom: 4,
              marginTop: -1,
              backgroundColor: 'transparent'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="secondary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                flexDirection: 'column'
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none' }}
              >
                <Typography fontSize={13}>Mehr erfahren</Typography>
              </Button>
            </AccordionSummary>
            <AccordionDetails>{items[1].description}</AccordionDetails>
          </Accordion>
        </Grid> */}
        <Grid
          item
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          sm={12}
          alignContent="left"
        >
          {/* <Carousel
            autoPlay={false}
            index={carouselIndex}
            indicators={false}
            navButtonsAlwaysInvisible
            animation="slide"
            onChange={(now?: number, previous?: number) =>
              setCarouselIndex(now!)
            }
          >
            {carouselItems}
          </Carousel> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SolutionCarousel;
