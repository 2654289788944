// material-ui
import { Container } from '@mui/material';

// project imports
import SolutionHeader from './SolutionHeader';
import SolutionVideo from './SolutionVideo';
import SolutionCarousel from './SolutionCarousel';

// ===============================|| Solution ||=============================== //

const SolutionBody = () => (
  <Container sx={{ marginBottom: '42px' }}>
    <SolutionHeader />
    <SolutionVideo />
    <SolutionCarousel />
  </Container>
);

export default SolutionBody;
