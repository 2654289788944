import { styled } from '@mui/material/styles';

// project imports
import AppBar from 'ui-component/extended/AppBar';
import Partners from './Partners';
import Sales from './Sales';
import Footer from './Footer';
import Customers from './Customers';
import { useTheme } from '@mui/material';

import HeaderWrapper from 'ui-component/HeaderWrapper';
import HeaderSection from 'views/pages/landing/HeaderSection';
import ManagementSection from './Management';
import GuidingSection from './Guiding';
import Benefits from './Benefits';
import SalesFab from './SalesFab';
import { useState } from 'react';

// =============================|| LANDING MAIN ||============================= //

const SectionWrapper = styled('div')({
  paddingTop: 40,
  paddingBottom: 80
});

const Landing = () => {
  const theme = useTheme();
  const [showFab, setShowFab] = useState(false);

  return (
    <>
      <HeaderWrapper
        id="home"
        sx={{
          background: `linear-gradient(360deg, ${theme.palette.background.paper} 1.09%, ${theme.palette.primary.light} 100%)`
        }}
      >
        <AppBar />
        <HeaderSection />
      </HeaderWrapper>
      <SectionWrapper
        sx={{ pb: '20px', paddingTop: { xs: '0px', md: '40px' } }}
      >
        <Customers />
      </SectionWrapper>
      <SectionWrapper
        sx={{
          background: `linear-gradient(360deg, ${theme.palette.primary.light} 1.09%, ${theme.palette.background.paper} 100%)`
        }}
      >
        <GuidingSection setShowFab={setShowFab} />
      </SectionWrapper>
      <SectionWrapper
        sx={{
          background: `linear-gradient(360deg, ${theme.palette.secondary.light} 1.09%, ${theme.palette.primary.light} 100%)`
        }}
      >
        <ManagementSection />
      </SectionWrapper>
      <SectionWrapper
        sx={{
          background: theme.palette.secondary.light
        }}
      >
        <Benefits setShowFab={setShowFab} />
      </SectionWrapper>
      <SectionWrapper
        sx={{
          background: `linear-gradient(360deg, ${theme.palette.background.paper} 1.09%, ${theme.palette.secondary.light} 100%)`
        }}
      >
        <Sales setShowFab={setShowFab} />
      </SectionWrapper>
      <SectionWrapper
        sx={{
          background: theme.palette.background.paper
        }}
      >
        <Partners setShowFab={setShowFab} />
      </SectionWrapper>
      <Footer />
      <SalesFab showFab={showFab} />
    </>
  );
};

export default Landing;
