import { ReactElement } from 'react';

// material-ui
import {
  Box,
  AppBar as MuiAppBar,
  Slide,
  Toolbar,
  useScrollTrigger
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports

// assets
import DesktopAppBar from './DesktopAppBar';
import MobileAppBar from './MobileAppBar';

// Hide scroll
export interface HideOnScrollProps {
  children: ReactElement;
  window?: Window | Node;
}
function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window!
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({ ...others }) => {
  const theme = useTheme();

  const elevationTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window!
  });

  return (
    <HideOnScroll {...others}>
      <MuiAppBar
        elevation={elevationTrigger ? 2 : 0}
        sx={{
          backgroundColor: theme.palette.primary.light
        }}
      >
        <Toolbar>
          {/* Mobile App Bar */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              width: '100%'
            }}
          >
            <DesktopAppBar />
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              width: '100%'
            }}
          >
            <MobileAppBar />
          </Box>
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

export default AppBar;
