import { Container, Grid, useTheme } from '@mui/material';

import ReactPlayer from 'react-player/lazy';

import { gridSpacing } from 'store/constant';

import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';

const SolutionVideo = () => {
  const theme = useTheme();
  return (
    <Container sx={{ my: 2 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={gridSpacing}
      >
        <Grid item xs={12} md={10} lg={8}>
          <div style={{ position: 'relative', paddingTop: '56.25%' }}>
            <ReactPlayer
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: theme.palette.primary.light,
                boxShadow: theme.shadows[16]
              }}
              className="react-player"
              url="videos/diingu-video.mp4"
              controls
              playing
              light="logo_long.png"
              width="100%"
              height="100%"
              playIcon={
                <PlayCircleRoundedIcon
                  sx={{
                    fontSize: '6rem',
                    color: theme.palette.primary.dark,
                    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'
                  }}
                />
              }
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SolutionVideo;
