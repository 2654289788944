import { useState } from 'react';

import {
  Box,
  SwipeableDrawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
  Button
} from '@mui/material';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { IconCoinEuro, IconHome, IconNews, IconUsers } from '@tabler/icons';

import Logo from 'ui-component/Logo';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

const MobileAppBar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const toggleDrawer = (newState: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(newState);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Link href="#/" display="flex" alignItems="center">
        <Logo />
      </Link>
      <IconButton
        onClick={toggleDrawer(true)}
        size="large"
        sx={{ color: theme.palette.grey[500] }}
      >
        <MenuRoundedIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box
          sx={{
            width: 250,
            height: '100%',
            backgroundColor: theme.palette.primary.light
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <List>
            <Link style={{ textDecoration: 'none' }} href="#">
              <ListItemButton>
                <ListItemIcon>
                  <IconHome color={theme.palette.primary.dark} />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </Link>
            {/* <Link style={{ textDecoration: 'none' }} href="#/solution">
              <ListItemButton>
                <ListItemIcon>
                  <IconSchool color={theme.palette.primary.dark} />
                </ListItemIcon>
                <ListItemText primary="Leistung" />
              </ListItemButton>
            </Link> */}
            <Link style={{ textDecoration: 'none' }} href="#/pricing">
              <ListItemButton>
                <ListItemIcon>
                  <IconCoinEuro color={theme.palette.primary.dark} />
                </ListItemIcon>
                <ListItemText primary="Preise" />
              </ListItemButton>
            </Link>
            <Link style={{ textDecoration: 'none' }} href="#/team">
              <ListItemButton>
                <ListItemIcon>
                  <IconUsers color={theme.palette.primary.dark} />
                </ListItemIcon>
                <ListItemText primary="Team" />
              </ListItemButton>
            </Link>
            <Link style={{ textDecoration: 'none' }} href="#/press">
              <ListItemButton>
                <ListItemIcon>
                  <IconNews color={theme.palette.primary.dark} />
                </ListItemIcon>
                <ListItemText primary="Presseportal" />
              </ListItemButton>
            </Link>
            {/* <Divider
              sx={{
                mx: 2,
                my: 1,
                borderColor: theme.palette.primary.dark
              }}
            /> */}
          </List>
          <Stack direction="column" spacing={2} sx={{ px: 2, py: 2 }}>
            <Button
              component={Link}
              variant="outlined"
              // color="secondary"
              href="https://app.diingu.de"
              sx={{
                textTransform: 'none'
              }}
            >
              Anmelden
            </Button>
            <Button
              component={Link}
              variant="contained"
              href="https://auth.app.diingu.de/realms/diingu/protocol/openid-connect/registrations?client_id=diingu-alb-client&response_type=code&scope=openid%20email&redirect_uri=https://app.diingu.de/&kc_locale=de"
              sx={{ textTransform: 'none' }}
              endIcon={<LoginRoundedIcon />}
            >
              Registrieren
            </Button>
          </Stack>
        </Box>
      </SwipeableDrawer>
    </Stack>
  );
};

export default MobileAppBar;
