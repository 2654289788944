// project imports
import HeaderWrapper from 'ui-component/HeaderWrapper';
import SecondWrapper from 'ui-component/SecondWrapper';
import PrivacyContent from './privacy';
import Footer from '../landing/Footer';
import AppBar from 'ui-component/extended/AppBar';

// =============================|| LANDING MAIN ||============================= //

const Privacy = () => (
  <>
    <HeaderWrapper id="privacy">
      <AppBar />
    </HeaderWrapper>
    <div style={{ minHeight: '100vh' }}>
      <SecondWrapper>
        <PrivacyContent />
      </SecondWrapper>
    </div>
    <Footer />
  </>
);

export default Privacy;
