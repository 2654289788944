// material-ui

const logoDir = require.context('assets/images');

// ==============================|| LOGO SVG ||============================== //

// TODO: Logo variants (dark, upright, ...)
const Logo = () => <img src={logoDir('./logo.svg')} alt="Diingu" width="170" />;

export default Logo;
