// material-ui
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';

import { motion } from 'framer-motion';

import imgLogo from 'assets/images/02_Logo_horizontal.png';
import imgTeam from 'assets/images/people/team-walking.jpg';
import imgUr from 'assets/images/landing/partners/ur_croped.png';
import imgAmico from 'assets/images/reading-amico.png';

import { useRef } from 'react';

// ===========================|| LANDING - CUSTOMIZE ||====================== //

const PressBody = () => {
  const theme = useTheme();
  const headerRef = useRef(null);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 4
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 1.5, sm: 2.5, md: 3, lg: 5 }}
      >
        <Grid item xs={12} sx={{ mb: { xs: 4, md: 0 } }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="h1"
              color="primary"
              textAlign="center"
              sx={{
                mb: 4,
                maxWidth: '37ch'
              }}
              ref={headerRef}
            >
              Unternehmensportrait
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: { xs: 8, sm: 10, md: 24 } }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 0, md: 124, lg: 96 },
                left: { xs: 32, sm: 100, md: 0, lg: 40 },
                width: { xs: 240, sm: 320, lg: 380 },
                zIndex: 3
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <img
                  src={imgLogo}
                  aria-label="Stack of 5 cards representing additional courses"
                />
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {!isLargeScreen && (
          <Grid item xs={12} md={6} textAlign="start">
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Die Story zur Gründungsidee
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      pb: 2
                    }}
                  >
                    Im Sommer 2020 kam unserem CEO Sahm Shojai nach regem
                    Austausch mit seinem Bruder die Gründungsidee für Diingu.
                    Dieser war damals selbst als Schulbegleiter tätig und
                    berichtete Sahm aus erster Hand über die Herausforderungen
                    in seinem Berufsfeld. Nach intensiver Recherche erkannte
                    Sahm ein bedeutendes Problem in der sozialen Branche:
                    Bedingt durch den Fachkräftemangel wird oftmals
                    unqualifiziertes Personal eingesetzt, was zu einer
                    Deprofessionalisierung und einem Mangel an pädagogischen
                    Qualitätsstandards führt.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isLargeScreen && (
          <Grid item xs={12} md={6} sx={{ mb: { sm: 0, md: 4, lg: 8 } }}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Die Story zur Gründungsidee
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      textAlign: 'end'
                    }}
                  >
                    Im Sommer 2020 kam unserem CEO Sahm Shojai nach regem
                    Austausch mit seinem Bruder die Gründungsidee für Diingu.
                    Dieser war damals selbst als Schulbegleiter tätig und
                    berichtete Sahm aus erster Hand über die Herausforderungen
                    in seinem Berufsfeld. Nach intensiver Recherche erkannte
                    Sahm ein bedeutendes Problem in der sozialen Branche:
                    Bedingt durch den Fachkräftemangel wird oftmals
                    unqualifiziertes Personal eingesetzt, was zu einer
                    Deprofessionalisierung und einem Mangel an pädagogischen
                    Qualitätsstandards führt.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isLargeScreen && (
          <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
            <Box sx={{ position: 'relative', py: { xs: 20, sm: 20, lg: 22 } }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: 64, sm: 48, md: 0, lg: 40 },
                  right: { xs: 0, sm: 60, md: 0 },
                  width: { xs: 320, sm: 400, md: 380, lg: 440 },
                  zIndex: 2
                }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 30
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-block',
                      boxShadow: theme.shadows[4],
                      borderRadius: '16px'
                    }}
                  >
                    <img
                      src={imgTeam}
                      alt="Descriptive alt text"
                      style={{
                        height: 'auto',
                        borderRadius: '16px',
                        display: 'block'
                      }}
                    />
                  </Box>
                </motion.div>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ mb: { sm: 0, md: 4, lg: 8 } }}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                Das Team & die Lösung
              </Typography>
              <Typography
                sx={{
                  zIndex: '99',
                  pb: 2
                }}
              >
                Seine Begeisterung für Digitalisierung und Technologie brachten
                den Unternehmensberater Sahm Shojai dazu, die benannten Probleme
                in der sozialen Branche durch eine E-Learning-Plattform mit
                pädagogischem Know-how zu adressieren, mit dem Ziel, neue
                Qualitätsstandards zu etablieren. Für die fachliche Expertise
                schloss er sich mit Felix Kolb zusammen, einem befreundeten
                psychologischen Psychotherapeuten. Gemeinsam mit dem
                Software-Entwickler Florian Kranz gründeten sie die Diingu GmbH.
                Nach monatelanger Entwicklung folgte im April 2023 der
                Soft-Launch. Zeitgleich trat der Künstler Hans Görlinger als
                letztes Puzzleteil dem Management-Team bei, um den Bereich
                Marketing zu verantworten.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {isLargeScreen && (
          <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
            <Box sx={{ position: 'relative', py: { xs: 16, sm: 20, lg: 22 } }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: 16, sm: 32, md: 0, lg: 40 },
                  right: { xs: 0, sm: 60, md: 0 },
                  width: { xs: 320, sm: 400, md: 380, lg: 440 },
                  zIndex: 2
                }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 30
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-block',
                      boxShadow: theme.shadows[4],
                      borderRadius: '16px'
                    }}
                  >
                    <img
                      src={imgTeam}
                      alt="Descriptive alt text"
                      style={{
                        height: 'auto',
                        borderRadius: '16px',
                        display: 'block'
                      }}
                    />
                  </Box>
                </motion.div>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: { xs: 16, lg: 20 } }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 48, sm: 32, lg: 32 },
                left: { xs: 8, sm: 100, md: 40 },
                width: { xs: 280, sm: 320, lg: 320 },
                zIndex: 2
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={imgUr}
                    alt="Card that shows an example certificate"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {!isLargeScreen && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="start">
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Zusammenarbeit mit Universitäten
                </Typography>
                <Typography
                  sx={{
                    zIndex: '99',
                    pb: 2
                  }}
                >
                  Um das Vorhaben bis zum Marktstart zu finanzieren, erfolgte
                  der Kontakt zum Lehrstuhlinhaber für inklusive Pädagogik der
                  Universität Regensburg, Prof. Dr. Wolfgang Dworschak. Dieser
                  erklärte sich bereit, dem Start-up-Vorhaben seine
                  wissenschaftliche Expertise als Fachmentor, im Rahmen des
                  Technologie-Stipendiums EXIST, zur Verfügung zu stellen. Kurz
                  darauf schloss sich Prof. Dr. Markus Gebhardt als weiterer
                  Fachmentor an. Als Lehrstuhlinhaber für Sonderpädagogik und
                  Lernen an der Ludwig-Maximilians-Universität München
                  unterstützt er Diingu ebenfalls tatkräftig.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isLargeScreen && (
          <Grid
            item
            container
            xs={12}
            md={6}
            sx={{ mb: { sm: 0, md: 4, lg: 8 } }}
          >
            <Grid item xs={12} textAlign="end">
              <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                Zusammenarbeit mit Universitäten
              </Typography>
              <Box display="flex" justifyContent="end" alignItems="end">
                <Typography
                  sx={{
                    zIndex: '99'
                  }}
                >
                  Um das Vorhaben bis zum Marktstart zu finanzieren, erfolgte
                  der Kontakt zum Lehrstuhlinhaber für inklusive Pädagogik der
                  Universität Regensburg, Prof. Dr. Wolfgang Dworschak. Dieser
                  erklärte sich bereit, dem Start-up-Vorhaben seine
                  wissenschaftliche Expertise als Fachmentor, im Rahmen des
                  Technologie-Stipendiums EXIST, zur Verfügung zu stellen. Kurz
                  darauf schloss sich Prof. Dr. Markus Gebhardt als weiterer
                  Fachmentor an. Als Lehrstuhlinhaber für Sonderpädagogik und
                  Lernen an der Ludwig-Maximilians-Universität München
                  unterstützt er Diingu ebenfalls tatkräftig.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {!isLargeScreen && (
          <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
            <Box sx={{ position: 'relative', py: { xs: 22, sm: 24 } }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: 48, sm: 64, lg: 48 },
                  right: { xs: 48, sm: 120, md: 40 },
                  width: { xs: 200, sm: 240, lg: 240 }
                }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1.5 }}
                  viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 30
                  }}
                >
                  <img
                    src={imgAmico}
                    aria-label="Stack of 5 cards representing additional courses"
                  />
                </motion.div>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item container xs={12} md={6}>
          <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
            Gemeinsam Inklusion gelingen lassen
          </Typography>
          <Typography
            sx={{
              zIndex: '99',
              mb: 4
            }}
          >
            Inklusion bedeutet für uns, allen Menschen gesellschaftliche und
            schulische Teilhabe zu ermöglichen. Dies kann nur gelingen, wenn
            alle verantwortlichen Akteurinnen und Akteure gemeinsam einheitliche
            pädagogische Qualitätsstandards setzen. Angefangen mit schulischer
            Inklusion, planen wir nun unsere Services auf weitere soziale
            Dienstleistungsbereiche auszuweiten und somit die soziale Branche
            auf nationaler und internationaler Ebene zu revolutionieren.
          </Typography>
        </Grid>
        {isLargeScreen && (
          <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
            <Box sx={{ position: 'relative', py: { xs: 12, sm: 16 } }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: 24, sm: 40, lg: 48 },
                  right: { xs: 48, sm: 120, md: 40 },
                  width: { xs: 200, sm: 240, lg: 240 }
                }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1.5 }}
                  viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 30
                  }}
                >
                  <img
                    src={imgAmico}
                    aria-label="Stack of 5 cards representing additional courses"
                  />
                </motion.div>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default PressBody;
