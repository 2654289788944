import { useRoutes } from 'react-router-dom';

import TeamPage from 'views/pages/team';
import PricingPage from 'views/pages/pricing';
import LandingPage from 'views/pages/landing';
import PrivacyPage from 'views/pages/privacy';
import SolutionPage from 'views/pages/solution';
import LegalPage from 'views/pages/legal';
import ErrorPage from 'views/maintenance/Error';
import MaintenancePage from 'views/maintenance/UnderConstruction';
import PressPage from 'views/pages/press';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: '/', element: <LandingPage /> },
    { path: '/solution', element: <SolutionPage /> },
    { path: '/team', element: <TeamPage /> },
    { path: '/pricing', element: <PricingPage /> },
    { path: '/privacy', element: <PrivacyPage /> },
    { path: '/legal', element: <LegalPage /> },
    { path: '/press', element: <PressPage /> },
    { path: '/maintenance', element: <MaintenancePage /> },
    { path: '*', element: <ErrorPage /> }
  ]);
}
