import { Container, Grid, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';

const SolutionHeader = () => (
  <Container>
    <Grid container spacing={gridSpacing} alignItems="center">
      <Grid item xs={12} lg={9} md={10}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h2" component="div">
              Digitale Akademie und Qualitätsmanagement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" component="div" gutterBottom>
              Unsere digitale Innovation kann Sie als freien Träger nach § 35a
              SGB VIII und § 112 SGB IX unterstützen - von der allgemeinen
              Fortbildung bis hin zur störungspezifischen Weiterbildung Ihrer
              Schulbegleitung.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default SolutionHeader;
