import { FunctionComponent, useRef } from 'react';

// material-ui
import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { gridSpacing } from 'store/constant';
import { KeyedObject } from 'types';
import SubCard from 'ui-component/cards/SubCard';

import {
  IconUsers,
  IconFeather,
  IconBuilding,
  IconDeviceDesktop,
  IconSchool,
  IconHeartHandshake
} from '@tabler/icons';

interface ItemProps extends KeyedObject {
  icon: JSX.Element;
  text: string;
}

// =============================|| LANDING - FEATURE PAGE ||============================= //

const PressBenefits = () => {
  const theme = useTheme();
  const headerRef = useRef(null);

  const Item = ({ icon, text }: ItemProps) => (
    <ListItem
      sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}
    >
      <ListItemIcon
        sx={{ color: theme.palette.primary.main, fontSize: '3rem' }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        sx={{ pl: 2 }}
        primary={text}
        primaryTypographyProps={{
          style: { color: theme.palette.primary.main },
          variant: 'subtitle2'
        }}
      />
    </ListItem>
  );

  type PressBenefitsCardProps = {
    title: string;
  };

  const PressBenefitsCard: FunctionComponent<PressBenefitsCardProps> = ({
    title,
    children
  }) => (
    <SubCard
      contentSX={{
        px: { xs: 2, lg: 4 },
        py: 4,
        height: '90%'
      }}
      sx={{
        boxShadow: theme.shadows[8],
        height: '100%'
      }}
      disableHover
    >
      <Typography
        variant="h3"
        color="primary"
        textAlign="center"
        sx={{ pb: 2 }}
      >
        {title}
      </Typography>
      <Box sx={{ height: '100%' }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          {children}
        </List>
      </Box>
    </SubCard>
  );

  return (
    <Container>
      <Grid container spacing={gridSpacing} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h1"
            component="div"
            color="primary"
            sx={{ mb: 8, mt: 2 }}
            ref={headerRef}
          >
            Unternehmensfakten
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PressBenefitsCard title="Das Team">
            <Item icon={<IconUsers size={32} />} text="8 Mitarbeitende" />
            <Item
              icon={<IconFeather size={32} />}
              text="6 wissenschaftliche Autorinnen & Autoren"
            />
          </PressBenefitsCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PressBenefitsCard title="Bundesweite Plattform">
            <Item
              icon={<IconBuilding size={32} />}
              text="Mehr als 50 soziale Träger als Kunden"
            />
            <Item
              icon={<IconDeviceDesktop size={32} />}
              text="Umfangreichstes Kursangebot zu schulischer Inklusion"
            />
          </PressBenefitsCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PressBenefitsCard title="Qualitätsstandards">
            <Item
              icon={<IconSchool size={32} />}
              text="Zusammenarbeit mit der Uni Regensburg & der LMU München"
            />
            <Item
              icon={<IconHeartHandshake size={32} />}
              text="Entwicklungs-Kooperationen mit sozialen Trägern"
            />
          </PressBenefitsCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PressBenefits;
