// material-ui
import { useTheme } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';

// project imports
import Footer from '../landing/Footer';
import HeaderWrapper from 'ui-component/HeaderWrapper';
import SecondWrapper from 'ui-component/SecondWrapper';
import AppBar from 'ui-component/extended/AppBar';

// assets
import PressHeader from './PressHeader';
import PressBody from './PressBody';
import PressTimeline from './PressTimeline';
import PressBenefits from './pressBenefits';
import PressPartners from './pressPartners';

// ============================|| SAAS PAGES - FAQs ||============================ //

const PressPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderWrapper>
        <AppBar />
      </HeaderWrapper>
      <div
        style={{
          minHeight: '100vh',
          background: `linear-gradient(360deg, ${theme.palette.background.paper} 1.09%, ${theme.palette.primary.light} 100%)`
        }}
      >
        <SecondWrapper>
          <Container sx={{ pb: '40px' }}>
            <Grid container justifyContent="center" spacing={8}>
              <Grid item xs={12} sx={{ pb: '24px' }}>
                <PressHeader />
              </Grid>
              <Grid item xs={12}>
                <PressBody />
              </Grid>
              <Grid item xs={12}>
                <PressBenefits />
              </Grid>
              <Grid item xs={12}>
                <PressPartners />
              </Grid>
              <Grid item xs={12} sx={{ pb: '5rem' }}>
                <PressTimeline />
              </Grid>
            </Grid>
          </Container>
        </SecondWrapper>
      </div>
      <Footer />
    </>
  );
};

export default PressPage;
