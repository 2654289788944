import { useRef, useEffect } from 'react';

import { Box, Container, Grid, Stack, Typography } from '@mui/material';

import 'react-multi-carousel/lib/styles.css';

import Slider, { Settings } from 'react-slick';

import { gridSpacing } from 'store/constant';
import { KeyedObject } from 'types';

import imgCbeef from 'assets/images/landing/customers/cebeef.svg';
import imgDieSchulbegleiter from 'assets/images/landing/customers/die-schulbegleiter.png';
import imgImpulsKbb from 'assets/images/landing/customers/impulskbb.png';
import imgLouiseScheppler from 'assets/images/landing/customers/luise-scheppler-heim.jpg';
import imgServiceteamWitt from 'assets/images/landing/customers/serviceteam-witt.svg';
import imgZusammenStark from 'assets/images/landing/customers/zusammen-stark.png';
import imgIncluedo from 'assets/images/landing/customers/incluedo.png';
import imgAsb from 'assets/images/landing/customers/asb.jpg';
import imgJohanniter from 'assets/images/landing/customers/johanniter.png';
import imgJusev from 'assets/images/landing/customers/jusev.png';
import imgScimoers from 'assets/images/landing/customers/scimoers.png';
import imgBaerenschule from 'assets/images/landing/customers/baerenschule.png';
import imgKjhv from 'assets/images/landing/customers/kjhv.png';
import imgPurzel from 'assets/images/landing/customers/purzel.png';
import imgMySchoolCare from 'assets/images/landing/customers/myschoolcare.png';
import imgMiladeus from 'assets/images/landing/customers/miladeus.png';
import imgAnDeinerSeite from 'assets/images/landing/customers/an-deiner-seite.png';
import imgAssistentium from 'assets/images/landing/customers/assistentium.png';

const customers = [
  {
    name: 'MySchoolCare',
    logo: imgMySchoolCare,
    link: 'https://www.myschoolcare.de/'
  },
  {
    name: 'ASB',
    logo: imgAsb,
    link: 'https://www.asb-wuerzburg.de'
  },
  {
    name: 'Johanniter',
    logo: imgJohanniter,
    link: 'https://www.johanniter.de/juh/lv-hrs/rv-hanau-main-kinzig/'
  },
  {
    name: 'Purzel',
    logo: imgPurzel,
    link: 'https://purzel.de/'
  },
  {
    name: 'Jusev',
    logo: imgJusev,
    link: 'https://www.jusev.de'
  },
  {
    name: 'Miladeus',
    logo: imgMiladeus,
    link: 'https://www.miladeus.de/'
  },
  {
    name: 'Bärenschule',
    logo: imgBaerenschule,
    link: 'https://www.baerenschule.de'
  },
  {
    name: 'Sci:moers',
    logo: imgScimoers,
    link: 'https://www.sci-moers.de'
  },
  {
    name: 'kjhv',
    logo: imgKjhv,
    link: 'https://kjhv-rheinland.de'
  },
  {
    name: 'An Deiner Seite e.V.',
    logo: imgAnDeinerSeite,
    link: 'https://www.andeinerseite-owl.de/'
  },
  {
    name: 'CeBeeF',
    logo: imgCbeef,
    link: 'https://www.cebeef.de/'
  },
  {
    name: 'die Schulbegleiter',
    logo: imgDieSchulbegleiter,
    link: 'https://www.dieschulbegleiter.de/'
  },
  {
    name: 'IMPULS KBB',
    logo: imgImpulsKbb,
    link: 'http://www.hilfen-für-familien.de/'
  },
  {
    name: 'Luise-Scheppler-Heim',
    logo: imgLouiseScheppler,
    link: 'http://luise-scheppler-heim.de/'
  },
  {
    name: 'Serviceteam-Witt',
    logo: imgServiceteamWitt,
    link: 'https://www.serviceteam-witt.de/home'
  },
  {
    name: 'Zusammen Stark',
    logo: imgZusammenStark,
    link: 'https://zusammen-stark.eu/'
  },
  {
    name: 'incluedo',
    logo: imgIncluedo,
    link: 'https://www.incluedo.de/'
  },
  {
    name: 'Assistentium.',
    logo: imgAssistentium,
    link: 'https://www.assistentium.de/'
  }
];

interface CustomerCarouselItemProps extends KeyedObject {
  img: string;
  alt: string;
  link?: string;
}

const CustomerCarouselItem = ({
  img,
  alt,
  link
}: CustomerCarouselItemProps) => (
  <Box
    sx={{
      height: '160px',
      p: { xs: '10px', md: '30px' }
    }}
    component={Stack}
    direction="column"
    justifyContent="center"
  >
    <a href={link} target="_blank" rel="noreferrer">
      <img
        src={img}
        alt={alt}
        loading="lazy"
        style={{ maxHeight: '160px', maxWidth: '100%' }}
      />
    </a>
  </Box>
);

function shiftAndAppend<T>(arr: T[], x: number): T[] {
  if (x === 0 || arr.length === 0) {
    return arr;
  }

  const normalizedShift = x % arr.length;
  const shiftedItems = arr.slice(0, normalizedShift);
  const remainingItems = arr.slice(normalizedShift);

  return remainingItems.concat(shiftedItems);
}

type VerticalCarouselProps = {
  sliderRef?: any;
  customerItems: any[];
};

const VerticalCarousel = ({
  sliderRef,
  customerItems
}: VerticalCarouselProps) => {
  const settings: Settings = {
    pauseOnHover: true,
    accessibility: true,
    autoplaySpeed: 5000,
    centerPadding: '0',
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    dots: false,
    arrows: false,
    vertical: true,
    slidesToScroll: 1,
    speed: 400,
    cssEase: 'cubic-bezier(1,-0.5,0,1.6)',
    useTransform: true
  };

  return (
    <Box
      sx={{
        div: {
          textAlign: 'center'
        }
      }}
    >
      <Slider {...settings} ref={sliderRef}>
        {customerItems.map((item, index) => (
          <CustomerCarouselItem
            key={index}
            img={item.logo}
            alt={`logo of the carrier ${item.name}`}
            link={item.link}
          />
        ))}
      </Slider>
    </Box>
  );
};

// TODO: Faster start for the sliders
const CustomerCarousel = () => {
  const slider1Ref = useRef<Slider>(null);
  const slider2Ref = useRef<Slider>(null);
  const slider3Ref = useRef<Slider>(null);
  const slider4Ref = useRef<Slider>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      slider1Ref.current?.slickPlay();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [slider1Ref]);

  useEffect(() => {
    const timer = setTimeout(() => {
      slider2Ref.current?.slickPlay();
    }, 4000); // 5 seconds delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      slider3Ref.current?.slickPlay();
    }, 2000); // 5 seconds delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      slider4Ref.current?.slickPlay();
    }, 3000); // 5 seconds delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container sx={{ mb: { xs: 0, md: 4 } }}>
      <Grid
        container
        spacing={gridSpacing}
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            component="div"
            textAlign="center"
            sx={{ fontWeight: 500, pt: 8, pb: 2 }}
          >
            Diese Träger vertrauen uns bereits:
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          sx={{ pt: { xs: '0 !important', sm: '2rem !important' } }}
        >
          <VerticalCarousel
            sliderRef={slider1Ref}
            customerItems={shiftAndAppend(customers, 0)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          sx={{ pt: { xs: '0 !important', sm: '2rem !important' } }}
        >
          <VerticalCarousel
            sliderRef={slider2Ref}
            customerItems={shiftAndAppend(customers, 2)}
          />
        </Grid>
        <Grid
          item
          sm={4}
          md={3}
          sx={{
            pt: { xs: '0 !important', sm: '2rem !important' },
            display: { xs: 'none', sm: 'inline' }
          }}
        >
          <VerticalCarousel
            sliderRef={slider3Ref}
            customerItems={shiftAndAppend(customers, 4)}
          />
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            pt: { xs: '0 !important', sm: '2rem !important' },
            display: { xs: 'none', md: 'inline' }
          }}
        >
          <VerticalCarousel
            sliderRef={slider4Ref}
            customerItems={shiftAndAppend(customers, 6)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerCarousel;
