import { useTheme } from '@mui/material';
import { styled } from '@mui/system';

import AppBar from 'ui-component/extended/AppBar';
import HeaderWrapper from 'ui-component/HeaderWrapper';

import Footer from '../landing/Footer';
import Team from './Team';
import TeamHeader from './TeamsHeader';

const SecondWrapper = styled('div')(({ theme }) => ({
  paddingTop: 100,
  marginBottom: -50,
  [theme.breakpoints.down('md')]: {
    paddingTop: 100,
    marginBottom: -50
  }
}));

const TeamPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderWrapper id="team">
        <AppBar />
      </HeaderWrapper>
      <div
        style={{
          minHeight: '100vh',
          background: `linear-gradient(360deg, ${theme.palette.background.paper} 1.09%, ${theme.palette.primary.light} 100%)`
        }}
      >
        <SecondWrapper>
          <TeamHeader />
        </SecondWrapper>
        <SecondWrapper>
          <Team />
        </SecondWrapper>
      </div>
      <Footer />
    </>
  );
};

export default TeamPage;
