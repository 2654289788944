// material-ui
import { Container, Grid, Typography, Link } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// ==============================|| LANDING - PRIVACY PAGE ||============================== //
const PrivacyContent = () => (
  <Container sx={{ mb: 4 }}>
    <Grid container spacing={gridSpacing} justifyContent="center">
      <Grid item container spacing={gridSpacing} xs={12} md={10} lg={8}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="h1" color="primary">
                Datenschutzerklärung
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="div">
            1. Datenschutz auf einen Blick
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Die folgenden Hinweise geben einen Überblick darüber, was mit Ihren
            personenbezogenen Daten passiert, wenn Sie diese Website besuchen
            oder Anwendungen, Dienstleistungen, Produkte bzw. Tools (folgend
            Services) von Diingu verwenden. Personenbezogene Daten sind alle
            Daten, mit denen Sie persönlich identifiziert werden können.
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="div">
            2. Verantwortliche Stelle
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website und den von Diingu bereitgestellten Services ist: <br />
            <br />
            Diingu GmbH
            <br />
            Brüsseler Str. 1-3 <br />
            60327 Frankfurt am Main
            <br /> <br />
            Kontakt:
            <br />
            Telefon: +49 173 372 44 95 <br />
            E-Mail: <Link href="mailto:info@diingu.de">info@diingu.de</Link>
            <br /> <br />
            Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
            anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.). <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="div">
            3. Hosting
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Diese Website und unsere Services werden bei externen Dienstleistern
            gehostet (Hoster). Die erfasst personenbezogenen Daten werden auf
            den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
            Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
            Daten, die über eine Website generiert werden, handeln. <br />
            Der Einsatz der Hoster erfolgt zum Zwecke der Vertragserfüllung
            gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
            lit. b DSGVO) und im Interesse einer sicheren, schnellen und
            effizienten Bereitstellung unseres Online-Angebots durch einen
            professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Unsere Hoster
            werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung
            Ihrer Leistungspflichten erforderlich ist und unsere Weisungen in
            Bezug auf diese Daten befolgen. <br /> <br />
            Wir setzen folgende Hoster ein: <br /> <br />
            Amazon Web Services (AWS) EMEA SARL <br />
            38 Avenue John F. Kennedy <br />
            L-1855, Luxembourg
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
            einen Vertrag über Auftragsverarbeitung mit unseren Hostern
            geschlossen.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="div">
            4. Datenerfassung
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            <b>AWS CloudFront und S3</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="div">
            Wir nutzen Dienste von Amazon Web Services (AWS), insbesondere
            CloudFront und S3, zum Hosting und zur Auslieferung unserer
            Webseite. AWS CloudFront ist ein Content Delivery Network (CDN), das
            den Zweck verfolgt, Inhalte unserer Webseite effizient und
            zuverlässig an Sie auszuliefern. AWS S3 (Simple Storage Service)
            wird zur Speicherung von Webinhalten eingesetzt. <br />
            <br />
          </Typography>
          <Typography variant="body2">
            <b>Datenverarbeitungszweck</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Sichere und effiziente Bereitstellung unserer Webinhalte.
          </Typography>
          <Typography variant="body2">
            <b>Art der gesammelten Daten</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Bei der Nutzung unserer Webseite können Daten wie Ihre IP-Adresse,
            Datum und Uhrzeit des Zugriffs, Browsertyp und -version,
            Referrer-URL und ähnliche technische Informationen erfasst werden.
          </Typography>
          <Typography variant="body2">
            <b>Rechtsgrundlage</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Art. 6 Abs. 1 lit. f DSGVO
          </Typography>
          <Typography variant="body2">
            <b>Speicherdauer</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Die Daten werden so lange gespeichert, wie es für den Zweck ihrer
            Erhebung notwendig ist oder bis jegliche rechtliche
            Aufbewahrungspflicht erfüllt ist.
          </Typography>
          <Typography variant="body2">
            <b>Drittanbieterinformationen</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109,
            USA
            <br />
            Weitere Informationen zum Datenschutz von AWS finden Sie unter:{' '}
            <Link
              href="https://aws.amazon.com/de/privacy/?nc1=h_ls"
              target="_blank"
            >
              https://aws.amazon.com/de/privacy/?nc1=h_ls
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            <b>Google Tag Manager</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="div" gutterBottom>
            Unsere Webseite verwendet den Google Tag Manager von Google LLC. Der
            Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags
            über eine Oberfläche verwalten können. Das Tool selbst (das die Tags
            implementiert) ist ein Cookie-loses Domain und erhebt keine
            personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer
            Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag
            Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder
            Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
            alle Tracking-Tags bestehen, die mit Google Tag Manager
            implementiert werden. <br />
            <br />
          </Typography>
          <Typography variant="body2">
            <b>Datenverarbeitungszweck</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Vereinfachung der Tag-Implementierung und -Verwaltung.
          </Typography>
          <Typography variant="body2">
            <b>Rechtsgrundlage</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Art. 6 Abs. 1 lit. f DSGVO
          </Typography>
          <Typography variant="body2">
            <b>Speicherdauer</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Die konkrete Speicherdauer der verarbeiteten Daten kann nicht
            beeinflusst werden, da die Daten von Google LLC kontrolliert werden.
          </Typography>
          <Typography variant="body2">
            <b>Drittanbieterinformationen</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
            <br />
            Weitere Informationen zum Datenschutz von Google finden Sie unter:{' '}
            <Link
              href="https://policies.google.com/privacy?hl=de"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=de
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            <b>Registrierung und Nutzerkonto</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="div">
            Für die Nutzung der Diingu-Plattform als Freemium-Nutzer müssen Sie
            ein Nutzerkonto anlegen. Dazu müssen Sie sich registrieren. Sie
            müssen dazu zwingend Ihre E-Mail-Adresse, ein selbst gewähltes
            Passwort sowie Ihren Namen und Vornamen angeben. Beim späteren
            Einloggen werden nur Ihre E-Mail-Adresse und das von Ihnen gewählte
            Passwort benötigt.
            <br /> <br />
            Wir verwenden für die Registrierung von Nutzern das sog.
            Double-Opt-In-Verfahren, d. h. Ihre Registrierung ist erst
            abgeschlossen, wenn Sie zuvor Ihre Registrierung über eine Ihnen zu
            diesem Zweck zugesandte Bestätigungs-E-Mail durch Klick auf den
            darin enthaltenen Link bestätigt haben. <br />
            Wenn Sie sich als Freemium-Nutzer registrieren, um unsere Plattform
            zu nutzen, verarbeiten wir Ihre Daten zur Erfüllung eines Vertrags
            oder zur Durchführung vorvertraglicher Maßnahmen gemäß Art. 6 Abs. 1
            lit. b DSGVO. Ihre Daten werden von uns gespeichert bis Sie Ihr
            Nutzerkonto löschen.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="div">
            5. Allgemeine Hinweise und Pflichtinformationen
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Datenschutz
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. <br />
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende <br />
            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür
            wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
            geschieht. <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich. <br />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Speicherdauer
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe. <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Auf unserer Website sind unter anderem Tools von Unternehmen mit
            Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren
            Drittstaaten eingebunden. Wenn diese Tools aktiv sind, können Ihre
            personenbezogene Daten in diese Drittstaaten übertragen und dort
            verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern
            kein mit der EU vergleichbares Datenschutzniveau garantiert werden
            kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss. <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO
            <br />
            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
            IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
            PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR
            EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
            RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
            DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
            WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN,
            ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
            VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
            ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
            ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21
            ABS. 1 DSGVO). <br />
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat Ihres gewöhnlichen Aufenthalts, Ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Recht auf Datenübertragbarkeit
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            SSL- bzw. TLS-Verschlüsselung
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.{' '}
            <br />
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Auskunft, Löschung und Berichtigung
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Recht auf Einschränkung der Verarbeitung
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen: <br />
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen. <br />
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah/geschieht, können Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen. <br />
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
            jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
            eingelegt haben, muss eine Abwägung zwischen Ihren und unseren
            Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
            Interessen überwiegen, haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen. <br />
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten - von Ihrer Speicherung
            abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Widerspruch gegen Werbe-E-Mails
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Kontaktformular
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter. <br />
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde. <br />
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt. <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            Anfrage per E-Mail, Telefon oder Telefax
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter. <br />
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde. <br />
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen - insbesondere
            gesetzliche Aufbewahrungsfristen - bleiben unberührt.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default PrivacyContent;
