import { Box, Typography, useTheme, Link } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent';

type NewspaperEntry = {
  title: string;
  publisher: JSX.Element;
  date: string;
  url: string;
};

const newspaperEntries: NewspaperEntry[] = [
  {
    title:
      'Regensburger Start-up Diingu will Schulbegleiter für behinderte Kinder gezielt schulen',
    publisher: (
      <>
        Mittel
        <wbr />
        bayerische
        <wbr /> Zeitung
      </>
    ),
    url:
      'https://www.mittelbayerische.de/lokales/stadt-regensburg/ausbildung-von-schulbegleitern-kaum-reguliert-start-up-aus-regensburg-will-standards-setzen-15479036',
    date: '20.02.2024'
  },
  {
    title: 'Regensburger Start-up will Schulbegleiter qualifizieren',
    publisher: <>idowa.de</>,
    url:
      'https://www.idowa.de/regionen/woerth-und-regensburg/regensburg/regensburger-start-up-will-schulbegleiter-qualifizieren-3517430.html',
    date: '02.01.2024'
  },
  {
    title:
      'Inklusion soll gelingen: Softwarelösung zur Unterstützung von Schulbegleitungen',
    publisher: <>Bad Abbacher Kurier</>,
    url:
      'https://bad-abbacher-kurier.de/universität-und-wissenschaft/23623-frankfurter-gründerpreis-für-soziales-education-tech-startup-',
    date: '21.12.2023'
  },
  {
    title:
      'Frankfurter Gründerpreis für soziales Education-Tech Startup „Diingu“ aus der Universität Regensburg',
    publisher: <>O/HUB</>,
    url:
      'https://www.o-hub.de/2023/12/19/frankfurter-gruenderpreis-fuer-soziales-education-tech-startup-diingu-aus-der-universitaet-regensburg/ ',
    date: '19.12.2023'
  },
  {
    title: 'Diingu erhält Hochschulgründerpreis 202',
    publisher: <>Universität Regensburg</>,
    url:
      'https://www.uni-regensburg.de/humanwissenschaften/geistigbehindertenpaedagogik/startseite/index.html?tx_news_pi1%5Baction%5D=detail&tx_news_pi1%5Bcontroller%5D=News&tx_news_pi1%5BhideDate%5D=1&tx_news_pi1%5Bnews%5D=20971&tx_news_pi1%5BsimpleList%5D=1&cHash=7e5e8c740783bdfd5f8f2afb51f0bed8',
    date: '06.12.2023'
  },
  {
    title:
      '16 Start-ups pitchten bei dem Wettbewerb der drei Oberpfälzer Hochschulen an der OTH Regensburg',
    publisher: <>Oberpfalz Aktuell</>,
    url:
      'https://www.oberpfalz-aktuell.com/oth-start-ups-aus-der-oberpfalz-begeisterten/',
    date: '03.12.2023'
  },
  {
    title: 'Oberpfälzer Start-ups pitchen beim Hochschulgründertag',
    publisher: <>O/HUB</>,
    url:
      'https://www.o-hub.de/2023/11/28/oberpfalzer-start-ups-pitchen-beim-hochschulgrundertag/ ',
    date: '28.11.2023'
  },
  {
    title:
      'Croowy, Diingu und HAPPY: Gewinner des Frankfurter Gründerpreises stehen fest',
    publisher: <>FFM Aktuell</>,
    url:
      'https://www.frankfurt-tipp.de/ffm-aktuell/s/ugc/croowy-diingu-und-happy-gewinner-des-frankfurter-gruenderpreises-stehen-fest.html',
    date: '20.09.2023'
  },
  {
    title:
      'Buchungssoftware für Flugzeug-Crews gewinnt Frankfurter Gründerpreis',
    publisher: <>Frankfurter Allgemeine Zeitung</>,
    url:
      'https://www.faz.net/aktuell/rhein-main/wirtschaft/frankfurter-gruenderpreis-hotelbuchung-fuer-flugzeug-crews-gewinnt-19186170/leonie-godard-und-svenja-19186255.html ',
    date: '19.09.2023'
  },
  {
    title: 'Frankfurter Gründerpreis - Die Finalisten - Diingu',
    publisher: <>Rhein-Main-TV</>,
    url:
      'https://www.rheinmaintv.de/sendungen/beitrag-video/frankfurter-gruenderpreis-die-finalisten-diingu/vom-13.09.2023/',
    date: '13.09.2023'
  },
  {
    title: '5 Fragen an … Diingu',
    publisher: <>Station FrankfurtRheinMain</>,
    url: 'https://station-frankfurt.de/2023/08/21/5-fragen-an-diingu/',
    date: '21.08.2023'
  },
  {
    title: 'Start-Up Rheinmain: Diingu',
    publisher: <>Rhein-Main-TV</>,
    url:
      'https://www.rheinmaintv.de/sendungen/beitrag-video/start-up-rheinmain-diingu/vom-18.07.2023/',
    date: '18.07.2023'
  },
  {
    title: 'Zwei neue EXIST Gründerstipendien an der Universität Regensburg',
    publisher: <>Bad Abbacher Kurier</>,
    url:
      'https://bad-abbacher-kurier.de/universität-und-wissenschaft/19401-zwei-neue-exist-gründerstipendien-an-der-universität-regensburg',
    date: '31.05.2022'
  },
  {
    title: 'Diingu: Unterstützung für Schulbegleitungen',
    publisher: <>Universität Regensburg</>,
    url:
      'https://www.uni-regensburg.de/humanwissenschaften/geistigbehindertenpaedagogik/startseite/index.html?tx_news_pi1%5Baction%5D=detail&tx_news_pi1%5Bcontroller%5D=News&tx_news_pi1%5Bnews%5D=17046&cHash=2b0f8cf1628f5cc02a66cf34f6e213c4',
    date: '04.04.2022'
  }
];

const PressTimeline = () => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        variant="h1"
        sx={{ pb: 8 }}
        textAlign="center"
        color="primary"
      >
        Pressespiegel
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: { xs: 0.4, md: 0.3 }
          }
        }}
      >
        {newspaperEntries.map((entry, index) => (
          <TimelineItem sx={{ minHeight: '40px' }} key={index}>
            <TimelineOppositeContent color="textSecondary" sx={{ px: 0 }}>
              <Typography
                variant="h4"
                color={theme.palette.primary.main}
                sx={{
                  pt: '4px',
                  px: 0,
                  wordWrap: 'break-word',
                  hyphens: 'auto',
                  overflowWrap: 'anywhere'
                }}
              >
                <div lang="de">{entry.publisher}</div>
              </Typography>
            </TimelineOppositeContent>
            <TimelineContent>
              <Typography
                variant="h4"
                component={Link}
                target="_blank"
                href={entry.url}
                gutterBottom
                sx={{
                  pb: '2px',
                  fontWeight: 400,
                  textUnderlineOffset: '0.2rem',
                  textDecorationColor: theme.palette.text.secondary
                }}
              >
                {entry.date}: {entry.title}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default PressTimeline;
