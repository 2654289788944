import { PaletteMode } from '@mui/material';

const config: {
  basename: string;
  defaultPath: string;
  fontFamily: string;
  borderRadius: number;
  outlinedFilled: boolean;
  theme: PaletteMode;
  presetColor: string;
  i18n: string;
  rtlLayout: boolean;
  jwt: {
    secret: string;
    timeout: string;
  };
} = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/',
  fontFamily: `'Roboto Flex Variable', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'light', // light, dark
  presetColor: 'default',
  i18n: 'de', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days'
  }
};

export default config;
