import {
  CookieConsentBanner,
  triggerCookieConsentBanner
} from '@porscheofficial/cookie-consent-banner-react';

interface ConsentBannerPrpos {
  onAnalyticConsent: () => void;
}

const ConsentBanner = ({ onAnalyticConsent }: ConsentBannerPrpos) => {
  const initConsent = ({ acceptedCategories }: any) => {
    if (acceptedCategories.includes('analytics')) {
      onAnalyticConsent();
    }
  };

  return (
    <div style={{ zIndex: 9999, position: 'absolute', maxHeight: '70%' }}>
      <CookieConsentBanner
        class="cookie-consent"
        handlePreferencesUpdated={initConsent}
        handlePreferencesRestored={initConsent}
        btnLabelAcceptAndContinue="Zustimmen und weiter"
        btnLabelSelectAllAndContinue="Alles auswählen und weiter"
        btnLabelOnlyEssentialAndContinue="Nur technisch notwendige akzeptieren"
        btnLabelPersistSelectionAndContinue="Auswahl speichern und weiter"
        contentSettingsDescription="Sie können entscheiden, welche Cookies verwendet werden, indem Sie die jeweiligen Optionen unten auswählen. Bitte beachten Sie, dass Ihre Auswahl die Funktionalität des Dienstes beeinträchtigen kann."
        availableCategories={[
          {
            description:
              'Ermöglicht Ihnen das Navigieren und Verwenden der Grundfunktionen sowie das Speichern von Einstellungen.',
            key: 'technically_required',
            label: 'Technisch notwendige Cookies',
            isMandatory: true
          },
          {
            description:
              'Ermöglicht es uns festzustellen, wie Besucher mit unserem Dienst interagieren, um das Benutzererlebnis zu verbessern.',
            key: 'analytics',
            label: 'Analyse-Cookies'
          },
          {
            description:
              'Ermöglicht es uns, relevanten Inhalt und interessenbasierte Werbung anzubieten und zu bewerten.',
            key: 'marketing',
            label: 'Marketing-Cookies'
          }
        ]}
      >
        Wir verwenden Cookies und ähnliche Technologien, um bestimmte Funktionen
        bereitzustellen und das Benutzererlebnis zu verbessern. Je nach Zweck
        können Analyse- und Marketing-Cookies zusätzlich zu technisch
        notwendigen Cookies verwendet werden. Durch Klicken auf "Zustimmen und
        weiter", erklären Sie Ihr Einverständnis zur Verwendung der genannten
        Cookies.{' '}
        <button
          onClick={() => {
            triggerCookieConsentBanner({ showDetails: true });
          }}
          onKeyPress={() => {
            triggerCookieConsentBanner({ showDetails: true });
          }}
          type="button"
          style={{
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid white',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'white',
            transition: 'background-color 0.3s, color 0.3s',
            outline: 'none'
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = 'white'; // Change to white on hover
            e.currentTarget.style.color = 'black'; // Change text color to black on hover
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent'; // Revert back to transparent
            e.currentTarget.style.color = 'white'; // Revert text color to white
          }}
        >
          Hier
        </button>{' '}
        können Sie detaillierte Einstellungen vornehmen. Für weitere
        Informationen verweisen wir auf unsere{' '}
        <a href="/#/privacy">Datenschutzrichtlinie</a>.
      </CookieConsentBanner>
    </div>
  );
};

export default ConsentBanner;
