import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ThemeProvider, Theme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// store
import { DefaultRootStateProps } from 'types';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

import TagManager from 'react-gtm-module';

import ConsentBanner from 'ui-component/ConsentBanner';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line, @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// ==============================|| APP ||============================== //

const App = () => {
  const [isAnalyticConsentGiven, setAnalyticConsentGiven] = useState(false);
  const isProduction: boolean = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (isAnalyticConsentGiven && isProduction) {
      const tagManagerArgs = {
        gtmId: 'GTM-T7LF47LT'
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [isAnalyticConsentGiven, isProduction]);

  const handleAnalyticConsent = (): void => {
    setAnalyticConsentGiven(true);
  };

  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );

  return (
    <StyledEngineProvider injectFirst>
      <ConsentBanner onAnalyticConsent={handleAnalyticConsent} />
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        {/* RTL layout */}
        {/* <RTLLayout> */}
        <Locales>
          <NavigationScroll>
            <>
              <Routes />
              <Snackbar />
            </>
          </NavigationScroll>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
