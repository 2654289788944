import { useMemo } from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import {
  Button,
  Container,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';

// third party
import { motion } from 'framer-motion';

// import Slider, { Settings } from 'react-slick';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import BgLight from 'assets/images/landing/bg-hero-block-light.png';

// eslint-disable-next-line
import { scroller } from 'react-scroll';
import HeaderGuiding from './HeaderGuiding';
// import HeaderManagement from './HeaderManagement';

import mobileGuidings from 'assets/images/landing/header/mobileGuiding.png';
import mobileManagement from 'assets/images/landing/header/mobileManagement.png';

import imgFgp from 'assets/images/landing/partners/fgp_1-removebg-preview.png';
import imgBmwi from 'assets/images/landing/partners/bmwi-removebg-preview.png';
import imgUr from 'assets/images/landing/partners/ur_croped.png';

// styles
const HeaderAnimationImage = styled('img')({
  maxWidth: '95%',
  filter: 'drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))'
});

// ==============================|| LANDING - HEADER PAGE ||============================== //

const HeaderSection = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const headerSX = {
    display: 'inline'
    // fontSize: { xs: '28px !important', sm: '3rem !important' }
    // { xs: 'block', md: 'inline' }
  };

  const HeaderAnimationImagememo = useMemo(
    () => (
      <HeaderAnimationImage
        src={BgLight}
        sx={{
          display: { xs: 'none', md: 'flex' },
          position: 'absolute',
          filter: 'none',
          bottom: { md: 0 },
          right: 0,
          width: '50%',
          transformOrigin: '50% 50%'
        }}
      />
    ),
    []
  );

  // const settings: Settings = {
  //   pauseOnHover: false,
  //   draggable: false,
  //   infinite: true,
  //   autoplay: true,
  //   arrows: false,
  //   dots: false,
  //   slidesToShow: 1,
  //   className: 'header-slider',
  //   centerPadding: '400px'
  // };

  return (
    <Container
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: { xs: '56px', lg: 0 }
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: { xs: 4, md: 16, lg: 32 }, mb: { xs: 2.5, md: 5 } }}
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={6}>
            <Grid item xs={12} textAlign={{ xs: 'center', md: 'left' }}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ type: 'spring', stiffness: 150, damping: 30 }}
              >
                {/* <Stack spacing={1}> */}
                <Typography variant="h1" color="primary" sx={headerSX}>
                  Diingu bildet Schulbegleitungen digital fort und vereinfacht
                  die Verwaltung in Ihrem Träger
                </Typography>
                {/* </Stack> */}
              </motion.div>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: -2.5, textAlign: { xs: 'center', md: 'left' } }}
            >
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Typography
                  textAlign={{ xs: 'center', md: 'left' }}
                  color="text.primary"
                  variant="body1"
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  Unsere E-Learning-Plattform ermöglicht zeit- und
                  ortsunabhängig eine umfangreiche Fortbildung.
                  {isMedium && (
                    <> Für einen höheren pädagogischen Qualitätsstandard.</>
                  )}
                  <br /> Damit Inklusion gelingt.
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} sx={{ pt: { xs: '2rem !important' } }}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30,
                  delay: 0.4
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}
                >
                  {isLarge && (
                    <Grid item>
                      <AnimateButton>
                        <Button
                          size="large"
                          variant="outlined"
                          href="/diingu-info-onepager-202408.pdf"
                          target="_blank"
                          endIcon={<DownloadRoundedIcon />}
                          sx={{ fontSize: '1.2rem', zIndex: 1 }}
                        >
                          Unsere Info PDF
                        </Button>
                      </AnimateButton>
                    </Grid>
                  )}
                  {isLarge && (
                    <Grid item>
                      <Button
                        onClick={() => {
                          scroller.scrollTo('salesContainer', {
                            duration: 4000,
                            smooth: true
                          });
                        }}
                        variant="contained"
                        endIcon={<PlayArrowIcon />}
                        sx={{
                          textTransform: 'none',
                          fontSize: '1.2rem',
                          zIndex: 1
                        }}
                        size="large"
                      >
                        Kostenlos testen
                      </Button>
                    </Grid>
                  )}
                  {!isLarge && (
                    <>
                      <Grid item xs={6} textAlign="left">
                        <img
                          src={mobileManagement}
                          alt="mobile management"
                          style={{
                            maxWidth: '180%',
                            position: 'relative',
                            filter:
                              'drop-shadow(0px 0px 20px rgb(33 150 243 / 10%))'
                            // right: '-120px'
                            // top: '-140px'
                          }}
                        />
                        {/* <Button variant="contained" sx={{ top: -40 }}>
                          Verwaltung
                        </Button> */}
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <motion.div
                          initial={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            type: 'spring',
                            stiffness: 150,
                            damping: 30,
                            delay: 0.4
                          }}
                        >
                          <img
                            src={mobileGuidings}
                            alt="mobile Guidings"
                            style={{
                              maxWidth: '180%',
                              position: 'relative',
                              left: '-40px',
                              top: '-10px',
                              zIndex: 4,
                              animation: '8s slideY linear infinite',
                              filter:
                                'drop-shadow(0px 0px 20px rgb(33 150 243 / 10%))'
                            }}
                          />
                        </motion.div>
                        {/* <Button variant="contained" sx={{ top: -24 }}>
                          Kursangebot
                        </Button> */}
                      </Grid>
                      <Grid item xs={12} textAlign="center">
                        <Button
                          onClick={() => {
                            scroller.scrollTo('salesContainer', {
                              duration: 4000,
                              smooth: true
                            });
                          }}
                          variant="contained"
                          endIcon={<PlayArrowIcon />}
                          sx={{
                            textTransform: 'none',
                            mt: 4,
                            fontSize: '1.1rem'
                          }}
                          size="large"
                        >
                          Kostenlos testen
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <HeaderGuiding />
          {/* <Slider {...settings}> */}
          {/* <HeaderManagement /> */}
          {/* </Slider> */}
          {HeaderAnimationImagememo}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '100px'
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          spacing={6}
          sx={{ mt: 12 }}
        >
          {/* FGP */}
          <Grid
            item
            xs={5}
            sm={4}
            md={2}
            lg={2}
            sx={{ zIndex: 1, mx: { xs: 0, sm: 0, md: 4, lg: 6 } }}
            display="flex"
            justifyContent="center"
          >
            <a
              href="https://www.frankfurt-business.net/aktuelles/details/frankfurter-gruenderpreis-2023/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={imgFgp}
                alt="Logo Frankfurt Founder Award."
                loading="lazy"
                width="100%"
                style={{ maxWidth: '180px' }}
              />
            </a>
          </Grid>
          {/* BMWI */}
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            sx={{
              zIndex: 1,
              mx: { sm: 0, md: 4, lg: 6 },
              px: { md: 4, lg: 0 }
            }}
            display="flex"
            justifyContent="center"
          >
            <a
              href="https://www.bmwi.de/Navigation/DE/Home/home.html"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={imgBmwi}
                alt="Logo BMWi"
                loading="lazy"
                width="100%"
                style={{ maxWidth: '180px' }}
              />
            </a>
          </Grid>
          {/* Uni Regensburg */}
          <Grid
            item
            key="imgUr"
            xs={8}
            sm={4}
            md={3}
            lg={3}
            sx={{
              zIndex: 1,
              mx: { sm: 0, md: 4, lg: 6 },
              display: { xs: 'none', sm: 'block' }
            }}
          >
            <a
              href="https://www.uni-regensburg.de/humanwissenschaften/geistigbehindertenpaedagogik/forschung/index.html"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={imgUr}
                alt="Logo Universität Regensburg"
                loading="lazy"
                width="100%"
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeaderSection;
