import Fab from '@mui/material/Fab';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KeyedObject } from 'types';

interface SalesFabProps extends KeyedObject {
  showFab: boolean;
}

const SalesFab = ({ showFab }: SalesFabProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' is for small devices (mobile)

  const phoneNumber = 'tel:+49 173 372 4495';
  const emailAddress = 'mailto:sales@diingu.de';

  return (
    <>
      {showFab && (
        <div
          style={{
            position: 'fixed',
            bottom: isMobile ? '24px' : '42px',
            right: isMobile ? '24px' : '42px',
            zIndex: 999
          }}
        >
          <Fab
            color="primary"
            href={isMobile ? phoneNumber : emailAddress}
            variant="extended"
            size="large"
            sx={{
              fontSize: isMobile ? '1rem' : '1.2rem',
              textTransform: 'none'
            }}
          >
            {isMobile ? (
              <PhoneIcon sx={{ mr: 1 }} />
            ) : (
              <MailIcon sx={{ mr: 1 }} />
            )}
            Direkt kontaktieren
          </Fab>
        </div>
      )}
    </>
  );
};

export default SalesFab;
