// TeamCard.tsx

import { makeStyles } from '@mui/styles';
import { Card, Typography, CardMedia, Stack, IconButton } from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';

type TeamMemberProps = {
  imageUrl: string;
  name: JSX.Element;
  title: string;
  overlayText: JSX.Element;
  email?: string;
  linkedIn?: string;
  web?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    position: 'relative'
  },
  media: {
    height: 321,
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      height: 260,
      backgroundPosition: 'top'
    }
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.9)',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1
    },
    fontSize: '20px'
  },
  buttonContainer: {
    position: 'absolute',
    bottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    width: '80%'
  }
}));

const TeamCard: React.FC<TeamMemberProps> = ({
  imageUrl,
  name,
  title,
  overlayText,
  email,
  linkedIn,
  web
}) => {
  const classes = useStyles();
  const iconSizeLarge = '2rem';
  const iconSizeSmall = '1.5rem';

  return (
    <Stack textAlign="center" spacing={2} sx={{ mb: 2 }}>
      <Card className={classes.root} elevation={4}>
        <CardMedia className={classes.media} image={imageUrl} />
        <div className={classes.overlay}>
          <Typography
            color="primary"
            sx={{
              px: 2,
              py: { xs: 2, md: 4 },
              letterSpacing: '-3%',
              lineHeight: { xs: '18px', md: '24px' },
              fontSize: { xs: '16px', md: '20px' }
            }}
          >
            {overlayText}
          </Typography>
          <div className={classes.buttonContainer}>
            {!!email && (
              <IconButton
                color="primary"
                href={`mailto:${email}`}
                target="_blank"
                size="large"
                rel="noopener noreferrer"
                sx={{
                  '& svg': {
                    fontSize: { xs: iconSizeSmall, sm: iconSizeLarge }
                  },
                  pb: { xs: 0, sm: '12px' }
                }}
              >
                <EmailIcon />
              </IconButton>
            )}
            {!!linkedIn && (
              <IconButton
                color="primary"
                href={linkedIn}
                target="_blank"
                size="large"
                rel="noopener noreferrer"
                sx={{
                  '& svg': {
                    fontSize: { xs: iconSizeSmall, sm: iconSizeLarge }
                  },
                  pb: { xs: 0, sm: '12px' }
                }}
              >
                <LinkedInIcon />
              </IconButton>
            )}
            {!!web && (
              <IconButton
                color="primary"
                href={web}
                target="_blank"
                size="large"
                rel="noopener noreferrer"
                sx={{
                  '& svg': {
                    fontSize: { xs: iconSizeSmall, sm: iconSizeLarge }
                  },
                  pb: { xs: 0, sm: '12px' }
                }}
              >
                <LanguageIcon />
              </IconButton>
            )}
          </div>
        </div>
      </Card>
      <Typography gutterBottom variant="h2" component="div" color="primary">
        {name}
      </Typography>
      <Typography
        sx={{
          mt: '0.2rem !important',
          lineHeight: '24px',
          fontSize: { xs: '1rem', sm: '1.25rem' }
        }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default TeamCard;
