import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from './reducer';

import { devToolsEnhancer } from 'redux-devtools-extension';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, devToolsEnhancer({}));

const persister = persistStore(store);

export { store, persister };
